import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import SendIcon from '@mui/icons-material/Send'
import { Button, Divider, Paper, TableContainer, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from 'react';
import { withRouter } from "react-router-dom";
import { styleAdmin } from "./styleAdmin";
import { useAdmin } from "./useAdmin";
import AllBookings from "./AllBookings";

function Admin(props) {
  const classes = styleAdmin();
  const { newUsers, verifiedUsers, getDate, verify, deleteUser, redirectToTour, resendVerificationEmail } = useAdmin(props);

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Typography variant="subtitle1"><b>Tour settings</b></Typography>
        <Divider light />
        <Button
          variant="contained"
          color="primary"
          className={classes.tourpreviewbutton}
          startIcon={<WallpaperIcon />}
          onClick={redirectToTour}
        >
          Tour preview
        </Button>
      </div>

      <div className={classes.section}>
        <Typography variant="subtitle1"><b>User registrations</b></Typography>
        <Divider light />
        {(newUsers || verifiedUsers) &&

          ( newUsers.length > 0 || verifiedUsers.length > 0 ?

          <TableContainer component={Paper} className={classes.tablecontainer}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="sticky table"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell>Name</TableCell> */}
                  <TableCell>Company</TableCell>
                  <TableCell>Email address</TableCell>
                  <TableCell>Email verified?</TableCell>
                  <TableCell>Time registered</TableCell>
                  <TableCell>Verify</TableCell>
                  <TableCell>Resend verification email</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newUsers
                  .sort((a, b) => a.timeCreated < b.timeCreated)
                  .map((user, index) => {
                    return (
                      <TableRow key={user.id}>
                        <TableCell component="th" scope="row">
                          {user.company}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                    {user.email}
                  </TableCell> */}
                        <TableCell component="th" scope="row">
                          {user.email}
                        </TableCell>

                        <TableCell>{user.enabled ? "Yes" : "No"}</TableCell>
                        <TableCell>{getDate(user.timeCreated)}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                              verify(user.id);
                            }}
                            variant="contained"
                            disabled={!user.enabled}
                          >
                            <CheckIcon />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            color="warning"
                            onClick={() => {
                              resendVerificationEmail(user.id);
                            }}
                            variant="outlined"
                            disabled={user.enabled}
                          >
                            <SendIcon sx={{width: '15px'}} />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            color="secondary"
                            onClick={() => {
                              deleteUser(user.id);
                            }}
                            variant="outlined"
                          >
                            <CloseIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          :
          <Typography><i>No new users</i></Typography>
          )
        }
      </div>

      <div className={classes.section}>
        <Typography variant="subtitle1"><b>All upcoming bookings</b></Typography>
        <Divider light horizontal/>
        <AllBookings />

      </div>
    </div>
  )
}
//  <iframe src="https://192.168.199.99:3000/d-solo/rKckDas7k/vdt_analytics?orgId=1&theme=light&panelId=2" width="100%" height="300" frameborder="0"></iframe> 
// /* <iframe src="https://192.168.199.99:3000/d-solo/OS7-NUiGz/spring-boot-statistics?orgId=1&refresh=5s&theme=light&panelId=95" width="450" height="200" frameborder="0"></iframe>
// <iframe src="https://192.168.199.99:3000/d-solo/OS7-NUiGz/spring-boot-statistics?orgId=1&refresh=5s&theme=light&panelId=96" width="450" height="200" frameborder="0"></iframe>
// <iframe src="https://192.168.199.99:3000/d-solo/OS7-NUiGz/spring-boot-statistics?orgId=1&refresh=5s&theme=light&panelId=4" width="450" height="200" frameborder="0"></iframe>




export default withRouter(Admin);
