import { useState } from "react";

export const useTourSentence = () => {
    const [openNewBooking, setOpenNewBooking] = useState(false);

    const handleNewBookingOpen = () => {
        setOpenNewBooking(true);
    };

    const handleNewBookingClose = () => {
        setOpenNewBooking(false);
    };

    return {handleNewBookingClose, handleNewBookingOpen, openNewBooking};
}