
import { makeStyles } from "@mui/styles";
import { green, orange } from "@mui/material/colors";

const styleTourSentence = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.primary.main,
        width: "100%",
        marginBottom: 20
    },
    pagesubtitle: {
        margin: 5,
    },
    tourbutton: {
        color: "white",
        margin: 5,
    },
    startbutton: {
        backgroundColor: green[700],
        margin: 5,
    },
    waitbutton: {
        backgroundColor: orange[800],
        margin: 5,
    },
    redirectPaper: {
        backgroundColor: green[700],
        padding: 10,
        marginTop: 10,
        marginBottom: 10,
        width: "100%"
    },
    redirectText: {
        color: "white",
        fontSize: 15,
    }
}));

export { styleTourSentence};