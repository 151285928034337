
import { makeStyles } from "@mui/styles";

const styleSigning = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper2: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    typography: {

        caption: {
            fontStyle: 'italic',
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logotext: {
        marginTop: 0,
        fontSize: 22,
        fontWeight: 'bold',
    },
    formControl: {
        width: "100%"
    },
    titletext: {
        marginTop: 0,
        fontSize: 22,
        fontWeight: 'regular',
    },
    bottomnav: {
        color: theme.palette.primary.main,
    },
    logoimage: {
        width: '200px',
    }

}));

export { styleSigning };