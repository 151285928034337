import axios from "axios";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../shared/constants/constants";

export const useCameras = () => {
  const [cameras, setCameras] = useState([]);

  useEffect(() => {
    getAllCameras();
  }, []);

  const getAllCameras = () => {
    axios
      .get(API_BASE_URL + "cameras/all", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        const cameras = response.data;
        // console.log(cameras);
        setCameras(cameras);
      });
  };

  return { cameras };
};
