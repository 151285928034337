import {
  Box, CircularProgress, Grid, Typography
} from "@mui/material";
import React from 'react';
import { withRouter } from "react-router-dom";
import { usePrepareCameraDetail } from "./usePrepareCameraDetail";



function PrepareCameraDetail(props) {
  // const classes = stylePrepareCameraDetail();
  const { progress } = usePrepareCameraDetail(props);

  return (
    <Box sx={{
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      height: '100%'
    }}>
      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%' }} >

        <Grid item>
          <CircularProgress variant="determinate" value={progress} style={{ marginTop: 140 }} size={60} />


        </Grid>

        <Grid item>
          <Typography variant="h5" align="center" style={{ marginTop: 75, color: 'white' }}>
            {progress < 40 && "Fetching camera status..."}
            {progress > 40 && progress < 60 && "Preparing camera settings..."}
            {progress > 60 && progress < 80 && "Checking connection quality..."}
            {progress > 80 && "Starting camera stream..."}
          </Typography>


        </Grid>
      </Grid>

    </Box>
  );
}

export default withRouter(PrepareCameraDetail);
