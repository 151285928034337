import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import React from "react";
import { styleConfirmation } from "./styleConfirmation";
import { useConfirmation } from "./useConfirmation";

export default function Confirmation(props) {
    const classes = styleConfirmation();
    const { getSlotStartTimeString, getSlotEndTimeString } = useConfirmation();

    return (
        <React.Fragment>
            <Grid container direction="column" alignItems="center">
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    className={classes.pickertext}
                >
                    Booking confirmation
                </Typography>

                <Paper variant="outlined" className={classes.root}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={classes.groot}
                    >
                        <Grid item>
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                align="center"
                            >
                                Date
                                
                            </Typography>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                                className={classes.datatext}
                            >
                                {props.selectedDate.toLocaleDateString()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                align="center"
                            >
                                Time
                            </Typography>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                                className={classes.datatext}
                            >
                                {getSlotStartTimeString(
                                    props.selectedSlots.sort(
                                        (a, b) => a.getTime() - b.getTime()
                                    )[0]
                                )}{" "}
                                –{" "}
                                {getSlotEndTimeString(
                                    props.selectedSlots.sort((a, b) => a.getTime() - b.getTime())[
                                    props.selectedSlots.length - 1
                                    ]
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            
                <Alert severity="success" >
                    You have successfully booked a new tour. We have emailed you a booking confirmation with a calendar invite. See you soon!
                </Alert>
                
            </Grid>
        </React.Fragment>
    );
}
