
import { makeStyles } from "@mui/styles";
import { green, grey, orange } from "@mui/material/colors";

const styleBookingOverview = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 800,
        background: "white"
    },
    startbutton: {
        color: "white",
        background: green[700],
        marginBottom: 20,
    },
    newbookingbutton: {
        color: "white",
        background: theme.palette.primary.main,
        marginBottom: 20,
    },
    changebutton: {
        color: "white",
        background: orange[700],
        marginRight: 20,
    },
    cancelbutton: {
        color: "white",
    },
    statuschip: {
        marginRight: 20,
        width: 85,
        color: "white",
    },
    confirmedchip: {
        background: green[700],
    },
    cancelchip: {
        background: grey[700],
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    upcomingSection: {
        marginBottom: 20,
    },
    pagetitle: {
        marginBottom: 20,
    },
    divider: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

export { styleBookingOverview};