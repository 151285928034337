import { useState } from "react";
import { useServerApi } from "../../../shared/controller/useServerApi";

export const useLogin = (props) => {
    const { login, otpTextfieldOpen,loginWithOtp } = useServerApi(props);
    const [loginResponse, setLoginResponse] = useState();

    const [state , setState] = useState({
        email : "",
        password : "",
        otp: "",
        successMessage: null
    })
  
    const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
  }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        otpTextfieldOpen ?
        loginWithOtp(state.email, state.password, state.otp, setLoginResponse)
        :
        login(state.email, state.password, setLoginResponse);
    }

    return {state, setState, handleChange, handleSubmit, loginResponse, otpTextfieldOpen};
}