import { makeStyles } from "@mui/styles";
import { orange } from "@mui/material/colors";

const styleWaitingRoom = makeStyles((theme) => ({
  paper: {
    marginTop: 60,
    marginBottom: 40,
    backgroundColor: "white",
  },
  innerpaper: {
    padding: 10,
    marginTop: 60,
    marginBottom: 40,
    backgroundColor: orange[700],
  },
  title: {
    margin: 10,
    color: "white",
  },
   subtitle: {
    margin: 40,
     color: "black",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

export { styleWaitingRoom };
