import axios from "axios";
import { useEffect, useState } from "react";
import {
  ACCESS_TOKEN_NAME,
  API_BASE_URL,
  SLOTAMOUNT,
} from "../../../../shared/constants/constants";

export const useSlotForm = (props) => {
  const [takenSlots, setTakenSlots] = useState([]);

  const handleSlotClick = (slot) => {
    // console.log(slot);
    //If not selected yet
    if (!props.selectedSlots.includes(slot)) {
      //If less than 4 selected
      if (props.selectedSlots.length < SLOTAMOUNT) {
        //If not first element and adjacent to previous selected
        const index = props.slots.indexOf(slot);
        if (
          props.selectedSlots.length > 0 &&
          !props.selectedSlots.includes(props.slots[index - 1]) &&
          !props.selectedSlots.includes(props.slots[index + 1])
        ) {
          return;
        }
        const a = [...props.selectedSlots];
        a.push(slot);
        props.setSelectedSlots(a);
      }
      //If selected, unselect
    } else {
      //If gap in timeslots, return
      const i = props.slots.indexOf(slot);
      if (
        props.selectedSlots.includes(props.slots[i - 1]) &&
        props.selectedSlots.includes(props.slots[i + 1])
      ) {
        return;
      }
      const index = props.selectedSlots.indexOf(slot);
      const a = [...props.selectedSlots];
      if (index > -1) {
        a.splice(index, 1);
      }
      props.setSelectedSlots(a);
    }
  };

  const getSlotStartTimeString = (date) => {
    // // console.log(date);
    var starttime = new Date(date);
    let hours = starttime.getHours().toString().padStart(2, "0");
    let minutes = starttime.getMinutes().toString().padStart(2, "0");
    let string = hours + ":" + minutes;
    return string;
  };

  const getSlotEndTimeString = (date) => {
    var endtime = new Date(date);
    endtime.setMinutes(endtime.getMinutes() + 30);
    let hours = endtime.getHours().toString().padStart(2, "0");
    let minutes = endtime.getMinutes().toString().padStart(2, "0");
    let string = hours + ":" + minutes;
    return string;
  };

  useEffect(() => {
    
    var p1 = new Promise(
      // Resolver-Funktion kann den Promise sowohl auflösen als auch verwerfen
      // reject the promise
      function(resolve, reject) {
        // refillSlots();
        resolve();
      });

      p1.then (
        function() {
          const year = props.selectedDate.getFullYear().toString();
        const month = (props.selectedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = props.selectedDate.getDate().toString().padStart(2, "0");
        const params = {
          date: year + "-" + month + "-" + day,
        };
        axios
          .get(API_BASE_URL + "bookings/slots", {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
            },
            params,
          })
          .then(function (response) {
            // console.log(response.data);
            setTakenSlots(response.data);
          });
        }
        
      )
    
  }, [props.selectedDate]);

  

  // const refillSlots = () => {
  //   var a = [];
  //   var i;
  //   for (i = 0; i < 48; i++) {
  //     let date = new Date(props.selectedDate + i * 30 * 60000);
  //       // console.log(date);
  //     if (!(date < Date.now() && sameDay(date, Date.now()))) {
  //       a.push(date);
  //     } 
  //   }
    
  //   props.setSlots(a);
  // }

  // function sameDay(d1, d2) {
  //   return d1.getFullYear() === d2.getFullYear() &&
  //     d1.getMonth() === d2.getMonth() &&
  //     d1.getDate() === d2.getDate();
  // }

  return {
    takenSlots,
    handleSlotClick,
    getSlotStartTimeString,
    getSlotEndTimeString,
  };
};
