import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { withRouter } from "react-router-dom";
import { styleNewBooking } from "./styleNewBooking";
import { useNewBooking } from "./useNewBooking";

function NewBooking(props) {
  const classes = styleNewBooking();
  const {
    activeStep,
    selectedSlots,
    steps,
    handleNext,
    handleBack,
    getStepContent,
    handleBooking,
  } = useNewBooking(props);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      {!isMobile && (
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <div className={classes.paper}>
        <React.Fragment>
          {getStepContent(activeStep)}
          <div className={classes.buttons}>
            {(activeStep !== 0 && activeStep !== 3) && (
              <Button onClick={handleBack} className={classes.button}>
                Back
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={
                activeStep === 2 ? handleBooking : handleNext
              }
              className={classes.button}
              disabled={activeStep === 1 && selectedSlots.length === 0}
            >
              {activeStep < 2 && "Next"}
              {activeStep === 2 && "Submit booking"}
              {activeStep === 3 && "Close"}
            </Button>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
export default withRouter(NewBooking);
