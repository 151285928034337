import { Alert, AlertTitle } from "@mui/lab";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { withRouter } from "react-router-dom";
import logo from "../../../shared/logo.svg";
import BottomLinks from "../BottomLinks/BottomLinks";
import { styleSigning } from "../styleSigning";
import { useVerify } from "./useVerify";


function Verify(props) {
  const classes = styleSigning();
  const {verify} = useVerify(props);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Box component="span" m={3}>
          {/* <Paper elevation={1}> */}
          <div className={classes.paper2}>
            <img src={logo} className={classes.logoimage} alt="Panasonic Connect Europe Logo" />
            <div className={classes.logotext}>Virtual Demo Room</div>
          </div>

          {/* </Paper> */}
        </Box>


      </div>
      {/* <Box mt={8}>
          <Copyright />
        </Box> */}
      <Alert severity="success" className={classes.form}>
        <AlertTitle>Successfully verified email address. Redirecting you to login page...</AlertTitle>
      </Alert>
      <BottomLinks />
    </Container>
  );
}

export default withRouter(Verify);
