import axios from "axios";
import { useState } from "react";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../constants/constants";
// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const useServerApi = (props) => {

    /** SIGNING */
    const [otpTextfieldOpen, setOtpTextfieldOpen] = useState(false);
    const login = (email, password, setLoginResponse) => {
        const payload = {
            "email": email,
            "password": password,
        }
        const url = API_BASE_URL + 'login';
        axios.post(url, payload)
            .then(function (response) {
                if (response.status === 200) {
                    sessionStorage.setItem(ACCESS_TOKEN_NAME, response.data.jwt);
                    redirectToStart();
                } else if (response.status === 203) {
                    // console.log(response.headers);
                    setOtpTextfieldOpen(true);
                    setLoginResponse("We have sent you a one time password via Email. It is valid for 10minutes.");
                }
            })
            .catch(function (error) {

                if (error.message === "Request failed with status code 403") {
                    setLoginResponse("Wrong username or password");
                } else {
                    //TODO: Deactivate in production

                    setLoginResponse(error.message);
                }

            });
    };

    const loginWithOtp = (email, password, otp, setLoginResponse) => {
        const payload = {
            "email": email,
            "password": password,
            "otp": otp
        }
        const url = API_BASE_URL + 'login';
        axios.post(url, payload)
            .then(function (response) {
                // console.log(response);
                setLoginResponse("");
                if (response.status === 200) {
                    sessionStorage.setItem(ACCESS_TOKEN_NAME, response.data.jwt);
                    setLoginResponse("OTP Success")
                    redirectToStart();
                } 
            })
            .catch(function (error) {

                if (error.message === "Request failed with status code 403") {
                    setLoginResponse("Wrong username or password");
                } else {
                    //TODO: Deactivate in production

                    // setLoginResponse(error.message);
                }

            });
    };

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const register = (email, firstname, lastname, mobile, password, password_confirm, company, industry, branch, country, setRegistrationResponse, agreedToAGB, agreedToGDPR, captchaToken) => {
        setRegistrationResponse(null);
        if (firstname.length === 0) {
            setRegistrationResponse("Please enter your first name.");
            return;
        }
        if (lastname.length === 0) {
            setRegistrationResponse("Please enter your last name.");
            return;
        }
        if (mobile.length === 0) {
            setRegistrationResponse("Please enter your mobile phone number.");
            return;
        }

        if (!validateEmail(email)) {
            setRegistrationResponse("Invalid email address.");
            return;
        }

        
        if (password.length < 12) {
            setRegistrationResponse("Password has to be at least 12 characters long.");
            return;
        }


        if (password !== password_confirm) {
            setRegistrationResponse("Passwords do not match.");
            return;
        }

        
        // if (company.length === 0) {
        //     setRegistrationResponse("Please enter a company.");
        //     return;
        // }
        // if (industry.length === 0) {
        //     setRegistrationResponse("Please select an industry.");
        //     return;
        // }
        // if (branch.length === 0) {
        //     setRegistrationResponse("Please select a branch.");
        //     return;
        // }
        if (country.length === 0) {
            setRegistrationResponse("Please select a country.");
            return;
        }
        if (!agreedToAGB) {
            setRegistrationResponse("Please agree to our Terms and Conditions and Privacy Policy.");
            return;
        }
        // if (!agreedToGDPR) {
        //     setRegistrationResponse("Please agree to our information on data protection.");
        //     return;
        // }

        const payload = {
            email: email,
            password: password,
            password_confirm: password_confirm,
            company: company,
            industry: industry,
            branch: branch,
            country: country,
            captchaToken: captchaToken,
        }
        // console.log(payload);
        axios.post(API_BASE_URL + 'registration', payload)
            .then(function (response) {
                // console.log(response.data);
                if (response.data === "Succesfully created account") {
                    redirectToConfirm();
                } else {
                    setRegistrationResponse(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
                setRegistrationResponse("Server responded with error.");
            });
    };
    const logout = () => {
        sessionStorage.removeItem(ACCESS_TOKEN_NAME);
        redirectToLogin();
    };
    const verify = (token) => {
        const url = API_BASE_URL + 'registration/confirm?token=' + token;
        axios.get(url);
    };
    const reset = (email, token, setResetResponse) => {
        if (!validateEmail(email)) {
            setResetResponse("Invalid email address.");
            return;
        }
        
        const payload = {
            "email": email,
            "captchaToken": token
        }
        const url = API_BASE_URL + 'reset';
        axios.post(url, payload)
            .then(function (response) {
                if (response.status === 200) {
                    setResetResponse("Email sent.");
                }
            })
            .catch(function (error) {
                setResetResponse("Invalid email address.");
            });
    };
    const updatePassword = (password, password_confirm, token, setUpdatePasswordResponse) => {
        if (password.length < 12) {
            setUpdatePasswordResponse("Password has to be at least 12 characters long.");
            return;
        }


        if (password !== password_confirm) {
            setUpdatePasswordResponse("Passwords do not match.");
            return;
        }
        const payload = {
            "password": password,
            "passwordConfirm": password_confirm,
            "token": token,
        }
        const url = API_BASE_URL + 'reset/update';
        axios.post(url, payload)
            .then(function (response) {
                if (response.status === 200) {
                    setUpdatePasswordResponse(response.data);
                }
            })
            .catch(function (error) {
                setUpdatePasswordResponse(error.message);
            });
    };

    // const deleteUser = (id) => {
    //     const payload = {
    //         "id": id
    //     }
    //     const url = API_BASE_URL + 'user/delete';
    //     axios.get(url, payload)
    //         .then(function (response) {
    //             if (response.status === 200) {
    //                 setUpdatePasswordResponse(response.data);
    //             }
    //         })
    //         .catch(function (error) {
    //             setUpdatePasswordResponse(error.message);
    //         });
    // };

    const deleteUser = (id) => {
        // // console.log(props.history);
        // const now = new Date();
        const params = {
            id: id,
        };
        axios
            .get(API_BASE_URL + "user/delete", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
                },
                params,
            })
            .then(function (response) {
                if (response.data !== undefined) {
                    if (response.data === true) {
                        redirectToLogin();
                    }
                }

            });
    };

    /** BOOKING */
    async function upcomingBooking(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween) {
        const now = new Date();
        const params = {
            date: now,
        };
        axios
            .get(API_BASE_URL + "bookings/upcoming", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
                },
                params,
            })
            .then(function (response) {
                const d = response.data.date;
                if (d != null) {
                    const date = createDateFromServer(d);
                    setEarliestBookingDate(date);
                    setDaysBetween(daysBetweenTwoDates(new Date(), date));
                    if (date < new Date()) {
                        setBookingIsCurrent(true);
                    }
                } else {
                    setEarliestBookingDate(false);
                    setBookingIsCurrent(false);
                }
            });
    };

    const upcomingBookingWithRedirect = (setEarliestBookingDate, setBookingIsCurrent, setDaysBetween, setTimebetween) => {
        // console.log(props.history);
        const now = new Date();
        const params = {
            date: now,
        };
        axios
            .get(API_BASE_URL + "bookings/upcoming", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
                },
                params,
            })
            .then(function (response) {
                const d = response.data.date;
                if (d != null) {
                    const date = createDateFromServer(d);
                    setEarliestBookingDate(date);
                    setDaysBetween(daysBetweenTwoDates(new Date(), date));
                    if (date < new Date()) {
                        setBookingIsCurrent(true);
                    }
                    if (setTimebetween !== undefined) {
                        const dif = date - Date.now();
                        setTimebetween(dif);
                        if (props.history.location.pathname === "/dashboard/waitingroom" && dif < 0) {
                            redirectToTour();
                        }
                    }
                } else {
                    setEarliestBookingDate(false);
                    setBookingIsCurrent(false);
                }
            });
    };

    const newBooking = (selectedSlots, selectedDate, slots, activeStep, setActiveStep, setBookingError) => {


        var p1 = new Promise(
            function (resolve, reject) {
                // console.log("newbooking");
                const slotdate = selectedSlots.sort()[0];
                const startdate = selectedDate;
                startdate.setHours(slotdate.getHours());
                startdate.setMinutes(slotdate.getMinutes());

                const params = JSON.stringify({
                    date: startdate.toJSON(),
                    slots: selectedSlots.map((slot, i) => slots.indexOf(slot)),
                    timeZoneOffset: startdate.getTimezoneOffset()
                });
                // console.log(params);
                // console.log(startdate.getTimezoneOffset());
                resolve(params);
            }
        )
        p1.then(
            function (params) {
                // console.log(params);
                axios
                    .post(API_BASE_URL + "bookings/new",
                        params,
                        {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
                                "content-type": "application/json",
                            },
                        })
                    .then(function (response) {
                        // console.log(response);
                        if (response.data === "CREATED") {
                            setActiveStep(activeStep + 1);
                        } else if (response.data === "BAD_REQUEST") {
                            setBookingError("Server did not accept booking.")
                        }

                    })
            }

        )


    };

    const allUpcomingBookings = (now, setUpcomingBookings) => {
        const params = {
            date: now,
        };
        axios
            .get(API_BASE_URL + "bookings/allUpcoming", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
                },
                params,
            })
            .then(function (response) {
                const bookings = response.data;
                if (bookings.length > 0) {
                    var imported = [];
                    bookings.forEach(function (booking) {
                        booking.date = createDateFromServer(booking.date);
                        imported.push([booking.id, booking.date, booking.slots, booking.cancelled]);
                    });
                    // console.log(imported);
                    setUpcomingBookings(imported);
                }

            });
    };
    const cancelBooking = (id) => {
        const params = {
            id: id,
        };
        axios
            .get(API_BASE_URL + "bookings/cancel", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
                },
                params,
            })
            .then(function (response) {
                // console.log(response.data);
            });
    };

    /** CAMERAS */
    const allCameras = (setCameras, setReload) => {
        axios
            .get(API_BASE_URL + "cameras/all", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
                },
            })
            .then(function (response) {
                const cameras = response.data;
                // // console.log(response.data);
                if (cameras) {
                    var newcameras = [];
                    cameras
                        .sort((a, b) => a.id > b.id)
                        .forEach(camera => {
                            if (camera.status === 'ONLINE' || camera.status === 'STANDBY') {
                                newcameras.push(camera);
                                // // console.log(camera)

                            }
                        });
                    setCameras(newcameras);
                }

            });
    };




    /** REDIRECTIONS */

    const redirectToLogin = () => {
        props.history.push('/login');
    }
    const redirectToStart = () => {
        props.history.push('/dashboard');
    }
    const redirectToConfirm = () => {
        props.history.push('/confirm');
    }
    const redirectToBooking = () => {
        props.history.push('/dashboard/booking');
    }
    const redirectToAdmin = () => {
        props.history.push("/dashboard/admin");
    };
    const redirectToWaitingRoom = () => {
        props.history.push('/dashboard/waitingroom');
    }
    const redirectToTour = () => {
        props.history.push('/dashboard/tour');
    }
    const redirectToSettings = () => {
        props.history.push('/dashboard/settings');
    }

    /** UTILS */

    const createDateFromServer = (d) => {
        // const now = new Date();
        const newDate = new Date();

        newDate.setFullYear(d[0]);
        newDate.setMonth(d[1] - 1);
        newDate.setDate(d[2]);
        newDate.setHours(d[3], d[4], 0);

        const diff = newDate.getTimezoneOffset();
        const withDiff = new Date(newDate.getTime() - diff * 60000);

        return withDiff;
    }

    function daysBetweenTwoDates(first, second) {
        var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
        var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());
        var millisecondsPerDay = 1000 * 60 * 60 * 24;
        var millisBetween = two.getTime() - one.getTime();
        var days = millisBetween / millisecondsPerDay;
        return Math.floor(days);
    }

    return {
        login, register, logout, upcomingBooking, newBooking, allUpcomingBookings,
        redirectToBooking, allCameras, daysBetweenTwoDates, verify, redirectToLogin,
        cancelBooking, redirectToWaitingRoom, redirectToStart, redirectToAdmin, redirectToTour,
        upcomingBookingWithRedirect, reset, updatePassword, deleteUser, redirectToSettings, otpTextfieldOpen, loginWithOtp,
        createDateFromServer
    };
};
