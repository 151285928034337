import { useEffect, useState } from "react";
import { useServerApi } from "../../../shared/controller/useServerApi";

export const useWaitingRoom = (props) => {
  const { upcomingBookingWithRedirect, redirectToTour } = useServerApi(props);

  const [earliestBookingDate, setEarliestBookingDate] = useState();
  const [bookingIsCurrent, setBookingIsCurrent] = useState(false);
  const [daysBetween, setDaysBetween] = useState(-1);
  const [timeBetween, setTimebetween] = useState();


  // const reload = useCallback(() => {
  //   upcomingBookingWithRedirect(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween, setTimebetween);

  // }, [upcomingBookingWithRedirect]);

  function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }


  useEffect(() => {
    upcomingBookingWithRedirect(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween, setTimebetween);
    // reload();
    let iv = setInterval(() => {
      upcomingBookingWithRedirect(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween, setTimebetween);
      // reload();
    }, 60 * 1000)
    return () => {
      clearInterval(iv);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { earliestBookingDate, bookingIsCurrent, daysBetween, timeBetween, msToTime, redirectToTour };
};
