import {
  Divider,
  Grid, Paper,
  Typography} from "@mui/material";
import React from 'react';
import { withRouter } from "react-router-dom";
import { styleWaitingRoom } from "./styleWaitingRoom";
import { useWaitingRoom } from "./useWaitingRoom";
import Countdown from 'react-countdown';


function WaitingRoom(props) {
  const classes = styleWaitingRoom();
  const { daysBetween, timeBetween, redirectToTour } = useWaitingRoom(props);

  // Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    redirectToTour();
    return <Typography>Tour is being started...</Typography>;
  } else {
    // Render a countdown
    if (hours != 0) {
      return <span>{hours}h {minutes}min {seconds}s</span>;
    } else {
      return <span>{minutes}min {seconds}s</span>;
    }
    
  }
};

  return (
    <div>

      {/* Section title */}
      <Typography variant="h5" className={classes.pagetitle}>
        Waiting room
      </Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper} alignItems="center">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Paper className={classes.innerpaper}>
              {daysBetween > 0 &&
                <Typography align="center" variant="h5" className={classes.title}>
                  You have {daysBetween} days left until your tour starts.
                </Typography>
              }
              {daysBetween === 0 &&
                <div>

                  <Typography align="center" variant="h5" className={classes.title}>
                 
                    Your tour starts in  <Countdown date={Date.now() + timeBetween} renderer={renderer}/>
                  </Typography>

                </div>
              }
            </Paper>
            <Typography align="center" variant="body1" className={classes.subtitle}>
              You will be redirected automatically once the tour has started.
            </Typography>
          </Grid>
        </Grid>
      </Paper>


    </div>
  );
}

export default withRouter(WaitingRoom);
