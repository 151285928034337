import { useEffect, useState } from "react";
import { useServerApi } from "../../../shared/controller/useServerApi";

export const usePasswordReset = (props) => {
    const { reset } = useServerApi(props);
    const [resetResponse, setResetResponse] = useState();
    const [email, setEmail] = useState();

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://www.google.com/recaptcha/api.js?render=6LeGhdQjAAAAAB_xc4eIUz9w3b3Om4gyNIHOZQze";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
    }

    const handleEmailSubmit = async (e) => {
        const grecaptcha = window.grecaptcha;
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute('6LeGhdQjAAAAAB_xc4eIUz9w3b3Om4gyNIHOZQze', { action: 'submit' }).then(function (token) {
                reset(email, token, setResetResponse);
            })
        })
    }

    return { 
        email, setEmail, handleEmailChange, handleEmailSubmit, resetResponse 
    };
}