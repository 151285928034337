import { Box, Button, Divider, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import React from 'react';
import { withRouter } from "react-router-dom";
import SingleCamera from "../SingleCamera/SingleCamera";
import TourSentence from "../TourSentence/TourSentence";
import { styleStart } from "./styleStart";
import { useStart } from "./useStart";
import BookingOverview from "../Booking/BookingOverview/BookingOverview";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Admin from "../Admin/Admin";

function Start(props) {
  const classes = styleStart();
  const {
    earliestBookingDate,
    // redirectToBooking,
    bookingIsCurrent,
    cameras,
    daysBetween,
    // openNewBooking, setOpenNewBooking,
    // handleNewBookingOpen,
    // handleNewBookingClose,
    setReload
  } = useStart(props);

  if (props.user) {
    if (props.user.appUserRole !== "NEW") {
      return (
        <div>
          {/* Page title */}
          {/* <Typography variant="h5" className={classes.pagetitle}>Welcome to the virtual demo tool.</Typography> */}

          {/* <Divider className={classes.divider} /> */}

          {/* Upcoming tour welcoming */}
          {/* <TourSentence
            earliestBookingDate={earliestBookingDate}
            bookingIsCurrent={bookingIsCurrent}
            daysBetween={daysBetween}
            setReload={setReload}
          /> */}

          <TourSentence
            earliestBookingDate={earliestBookingDate}
            bookingIsCurrent={bookingIsCurrent}
            daysBetween={daysBetween}
            setReload={setReload}
          />


          {props.user.appUserRole === "ADMIN" &&
             <Accordion className={classes.adminPaper} >
             <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
             >
               <Typography variant="h6" className={classes.pagetitle}>Admin panel</Typography>
               
             </AccordionSummary>
             <AccordionDetails>
               <Admin />
             </AccordionDetails>
           </Accordion>
          }

        
              <BookingOverview />
           
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6" className={classes.pagetitle}>Connected devices</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Grid container spacing={3}>
                {cameras
                  // .sort((a, b) => a.timeCreated < b.timeCreated)
                  .map((camera) => {
                    return (
                      <Grid item xs={12} sm={6} lg={3} key={camera.id}>
                        <SingleCamera camera={camera} />
                      </Grid>
                    );
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>




          {/* <Divider className={classes.divider} /> */}

          {/* Page title */}

        </div>
      );
    } else {
      return (
        <div>
          <Grid container justifyContent="left">
          </Grid>
          <Alert severity="warning">Your account needs to be verified before you can start to book
            tours, which can usually take up to <b>48 hours</b>. We will send you an email once you are verified by an
            admin.</Alert>
          <Grid container justifyContent="center">

            <Box m={2}>
              <iframe width="840" height="472,5" src="https://www.youtube.com/embed/c4n41EAwsNo?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Box>
          </Grid>
        </div>
      );
    }
  } else {
    return (
      <CircularProgress color="secondary" />
    );
  }
}

export default withRouter(Start);