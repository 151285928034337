import React, { useEffect, useState } from 'react';
import { useServerApi } from "../../../shared/controller/useServerApi";
import Confirmation from "./Confirmation/Confirmation";
import DateForm from "./DateForm/DateForm";
import Review from "./Review/Review";
import SlotForm from "./SlotForm/SlotForm";

export const useNewBooking = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    new Date().setHours(0, 0, 0)
  );
  const [slots, setSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [bookingError, setBookingError] = useState();
  const steps = [
    "Select date",
    "Select available timeslot",
    "Review your booking",
    "Booking confirmation"
  ];
  const { newBooking } = useServerApi(props);

  const handleNext = () => {
    // // console.log(activeStep);
    if (activeStep === steps.length - 1) {
      props.handleClose();
      return;
    }
    setActiveStep(activeStep + 1);

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <DateForm
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        );
      case 1:
        return (
          <SlotForm
            slots={slots}
            setSlots={setSlots}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            selectedDate={selectedDate}
          />
        );
      case 2:
        return (
          <Review selectedDate={selectedDate} selectedSlots={selectedSlots} bookingError={bookingError} />
        );
      case 3:
        return (
          <Confirmation selectedDate={selectedDate} selectedSlots={selectedSlots} />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleBooking = () => {
    newBooking(selectedSlots, selectedDate, slots, activeStep, setActiveStep, setBookingError);
    props.setReload(true);
  };



  useEffect(() => {
    async function fetchData() {
      if (selectedDate !== undefined) {
        var a = [];
        var i;
        for (i = 0; i < 48; i++) {
          let date = new Date(selectedDate);
          date.setTime(date.setTime(date.getTime() + i * 30 * 60000));
          // // console.log(date);
          // if (date > Date.now()) {
            a.push(date);
          // }
        }
  
        setSlots(a);
      }
    }
    fetchData();
  }, [selectedDate]);

  

  return {
    activeStep,
    slots,
    selectedSlots,
    steps,
    handleNext,
    handleBack,
    getStepContent,
    handleBooking,

  };
};
