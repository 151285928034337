import { useEffect } from "react";
import { useServerApi } from "../../../shared/controller/useServerApi";

export const useVerify = (props) => {
    const { verify, redirectToLogin } = useServerApi(props);

    // const verifyWithTimer = useCallback((async) => {
    //     verify(props.match.params.token);
    //     const timer = setTimeout(() => {
    //         redirectToLogin();
    //     }, 3000);
    //     return () => clearTimeout(timer);
    // }, [props.match.params.token, redirectToLogin, verify]);

    useEffect(() => {
        verify(props.match.params.token);
        const timer = setTimeout(() => {
            // console.log("redirecting now")
            redirectToLogin();
        }, 3000);
        return () => clearTimeout(timer);
    }, []);


    return {verify};

    
}