import axios from "axios";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../../shared/constants/constants";

export const useCameraDetail = (props) => {
  const [speed, setSpeed] = useState(50);
  const [zoom, setZoom] = useState(50);
  const [focus, setFocus] = useState(50);
  const [isTurning, toggleTurning] = useState(false);
  const [iris, setIris] = useState(50);
  const [gain, setGain] = useState(0);
  const [wb, setWb] = useState(0);
  const [shutter, setShutter] = useState(0);
  const [autofocus, setAutofocus] = useState(0);
  const [autoiris, setAutoiris] = useState(0);
  const [selectedPreset, setSelectedPreset] = useState();
  const [commandProgress, setCommandProgress] = useState(0);
  const [lightDim, setLightDim] = useState(50);
  const [lightTemp, setLightTemp] = useState(50);

  const gainArray = [0, 3, 6, 9, 18, 80]
  const wbArray = [0, 1, 2, 3, 4, 5, 9]
  const shutterArray = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

  useEffect(() => {
    getCameraSettings();

    // console.log(props.selectedCamera);
    const handleTabClose = event => {
      event.preventDefault();

      // console.log('beforeunload event triggered');

      stopAllStreaming();
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      if (props.finished) {
        stopAllStreaming();
      }
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [props.finished]);// eslint-disable-line react-hooks/exhaustive-deps

  const getCameraSettings = () => {
    getAutofocus();
    getAutoiris();

    getFocus();
    getZoom();
    getIris();

    getGain();
    getWb();
    // getShutter();
  }




  const handleSelectedCameraChange = (cameraIndex) => {
    // if (props.selectedCamera != event.target.value) {
    //   props.setFinished(false);
    // }
    stopStreaming(props.selectedCamera.id)
    props.setSelectedCamera(props.cameras[cameraIndex]);
    props.setFinished(false);
  };

  const handleSelectedQualityChange = (streamNumber) => {
    stopStreaming(props.selectedCamera.id)
    props.setSelectedQuality(streamNumber);
    props.setFinished(false);
  };

  const handleSpeedChange = (event, newValue) => {
    setSpeed(newValue);
  };

  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  const handleZoomChangeCommitted = (event, newValue) => {
    zoomCamera(newValue);
    setZoom(newValue);
  };

  const handleFocusChange = (event, newValue) => {
    setFocus(newValue);
  };

  const handleFocusChangeCommitted = (event, newValue) => {
    focusCamera(newValue);
    setFocus(newValue);
  };

  const handleIrisChange = (event, newValue) => {
    setIris(newValue);
  };

  const handleIrisChangeCommitted = (event, newValue) => {
    irisCamera(newValue);
    setIris(newValue);
  };

  const handleLightDimChange = (event, newValue) => {
    setLightDim(newValue);
  };

  const handleLightDimChangeCommitted = (event, newValue) => {
    lightDimCamera(newValue);
    setLightDim(newValue);
  };

  const handleLightTempChange = (event, newValue) => {
    setLightTemp(newValue);
  };

  const handleLightTempChangeCommitted = (event, newValue) => {
    lightTempCamera(newValue);
    setLightTemp(newValue);
  };

  const triggerCommandProgress = () => {
    setCommandProgress(5);
  }

  const receivedResponseCommandProgress = () => {
    setCommandProgress(35);
  }

  const receivedSuccessCommandProgress = () => {
    setCommandProgress(55);
    setTimeout(() => {
      setCommandProgress(75);
      
      setTimeout(() => {
        setCommandProgress(100);
        getCameraSettings();
      }, 4000);
    }, 1000);
  }


  const turnCamera = (direction) => {
    triggerCommandProgress();
    if (!isTurning) {
      toggleTurning(true);
      axios.get(
        API_BASE_URL +
        "cameras/turn/" +
        props.selectedCamera.id +
        "/" +
        direction +
        "/" +
        speed,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        receivedResponseCommandProgress();
        if (response.status === 200) {
          receivedSuccessCommandProgress();
        }
      });
      
    }
  };

  const stopTurn = () => {
    if (isTurning) {
      toggleTurning(false);
      axios.get(API_BASE_URL + "cameras/stopturn/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      });
      // .then(function (response) {
      //   console.log(response.data);
      // });
    }
  };
  

  const zoomCamera = (value) => {
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL + "cameras/zoom/" + props.selectedCamera.id + "/" + value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        receivedResponseCommandProgress();
        if (response.status === 200) {
          receivedSuccessCommandProgress();
          setZoom(response.data);
        }
        
        
      });
  };

  const focusCamera = (value) => {
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL +
        "cameras/focus/" +
        props.selectedCamera.id +
        "/" +
        value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        
        receivedResponseCommandProgress();
        if (response.status === 200) {
          receivedSuccessCommandProgress();
          setFocus(response.data);
        }
      });
  };

  const irisCamera = (value) => {
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL +
        "cameras/iris/" +
        props.selectedCamera.id +
        "/" +
        value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        receivedResponseCommandProgress();
        if (response.status === 200) {
          receivedSuccessCommandProgress();
          setIris(value);
        }
      });
  };

  const gainCamera = (value) => {
    triggerCommandProgress();
    if (value >= 0 && value <= 120) {
      axios
        .get(
          API_BASE_URL +
          "cameras/gain/" +
          props.selectedCamera.id +
          "/" +
          value,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
            },
          }
        )
        .then(function (response) {
          // console.log(response.data);
          receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
            if (value === 80) {
              setGain("Auto");
            }
            setGain(value);
          }
        });
    }

  };

  const wbCamera = (value) => {
    // console.log(value);
    triggerCommandProgress();
    if (value >= 0 && value <= 30) {
      axios
        .get(
          API_BASE_URL +
          "cameras/wb/" +
          props.selectedCamera.id +
          "/" +
          value,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
            },
          }
        )
        .then(function (response) {
          receivedResponseCommandProgress();
          if (response.status === 200) {
            
            receivedSuccessCommandProgress();
            setWb(value);
          }
        });
    }

  };

  const shutterCamera = (value) => {
    triggerCommandProgress();
    if (value >= 0 && value <= 30) {
      axios
        .get(
          API_BASE_URL +
          "cameras/shutter/" +
          props.selectedCamera.id +
          "/" +
          value,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
            },
          }
        )
        .then(function (response) {
          receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
            setShutter(value);
          }
        });
    }

  };

  const lightDimCamera = (value) => {
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL +
        "cameras/dim/" +
        value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        
        receivedResponseCommandProgress();
        if (response.status === 200) {
          receivedSuccessCommandProgress();
          // console.log(response.data);
          setLightDim(response.data);
        }
      });
  };

  const lightTempCamera = (value) => {
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL +
        "cameras/color/" +
        value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        
        receivedResponseCommandProgress();
        if (response.status === 200) {
          receivedSuccessCommandProgress();
          // console.log(response.data);
          setLightTemp(response.data);
        }
      });
  };

  const getZoom = () => {
    axios
      .get(API_BASE_URL + "cameras/getzoom/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        setZoom(response.data);
      });
  };

  const getFocus = () => {
    axios
      .get(API_BASE_URL + "cameras/getfocus/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setFocus(response.data);
      });
  };

  const getIris = () => {
    axios
      .get(API_BASE_URL + "cameras/getiris/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        setIris(response.data);
      });
  };

  const getGain = () => {
    axios
      .get(API_BASE_URL + "cameras/getgain/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        // console.log("Get gain response: " + response.data);
        setGain(response.data);
      });
  };

  const getWb = () => {
    axios
      .get(API_BASE_URL + "cameras/getwb/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setWb(response.data);
      });
  };

  // const getShutter = () => {
  //   axios
  //     .get(API_BASE_URL + "cameras/getshutter/" + props.selectedCamera.id, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
  //       },
  //     })
  //     .then(function (response) {
  //       console.log(response.data);
  //       setShutter(response.data);
  //     });
  // };

  const getAutofocus = () => {
    axios
      .get(API_BASE_URL + "cameras/getautofocus/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setAutofocus(response.data);
      });
  };

  const getAutoiris = () => {
    axios
      .get(API_BASE_URL + "cameras/getautoiris/" + props.selectedCamera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setAutoiris(response.data);
      });
  };

  const getSinglePresetImage = (presetId) => {
    axios
      .get(API_BASE_URL + "cameras/presetimage/" + presetId, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        return response.data;
      });
    // props.setFinished(true);
  };

  const toggleAutofocus = (value) => {
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL +
        "cameras/setautofocus/" +
        props.selectedCamera.id +
        "/" +
        value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
            setAutofocus(response.data);
          }
      });
  };

  const toggleAutoiris = (value) => {
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL +
        "cameras/setautoiris/" +
        props.selectedCamera.id +
        "/" +
        value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
            setAutoiris(response.data);
          }
      });
  };

  const goToPreset = (value) => {
    // console.log(value);
    triggerCommandProgress();
    axios
      .get(
        API_BASE_URL +
        "cameras/preset/" +
        props.selectedCamera.id +
        "/" +
        value ,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        receivedResponseCommandProgress();
        
          if (response.status === 200) {
            
            receivedSuccessCommandProgress();
            setSelectedPreset(value);
          }
      });
  };

  const setPreset = (value) => {
    triggerCommandProgress();
    setSelectedPreset(value);
    
    axios
      .get(
        API_BASE_URL +
        "cameras/setpreset/" +
        props.selectedCamera.id +
        "/" +
        value,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        }
      )
      .then(function (response) {
        receivedResponseCommandProgress();
       
          if (response.status === 200) {
            receivedSuccessCommandProgress();
            
          }

      });
  };

  const stopStreaming = (id) => {
    axios
      .get(API_BASE_URL + "cameras/stop/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
  };

  const stopAllStreaming = () => {
    axios
      .get(API_BASE_URL + "cameras/stopall", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
  };

  const turnLight1On = () => {
    triggerCommandProgress();
    axios
      .get(API_BASE_URL + "cameras/light/on", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
          }
      });
  };

  const turnLight1Off = () => {
    triggerCommandProgress();
    axios
      .get(API_BASE_URL + "cameras/light/off", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
          }
      });
  };

  const turnLight2On = () => {
    triggerCommandProgress();
    axios
      .get(API_BASE_URL + "cameras/light2/on", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
          }
      });
  };

  const turnLight2Off = () => {
    triggerCommandProgress();
    axios
      .get(API_BASE_URL + "cameras/light2/off", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
          }
      });
  };

  const turnRail = (value) => {
    triggerCommandProgress();
    axios
      .get(API_BASE_URL + "cameras/rail/" + value, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        receivedResponseCommandProgress();
          if (response.status === 200) {
            receivedSuccessCommandProgress();
          }
      });
  };



  return {
    speed,
    setSpeed,
    zoom,
    setZoom,
    focus,
    setFocus,
    iris,
    setIris,
    handleSpeedChange,
    handleZoomChange,
    handleZoomChangeCommitted,
    handleFocusChange,
    handleFocusChangeCommitted,
    turnCamera,
    stopTurn,
    handleSelectedCameraChange,
    handleSelectedQualityChange,
    zoomCamera,
    focusCamera,
    autofocus,
    toggleAutofocus,
    selectedPreset,
    setSelectedPreset,
    goToPreset,
    setPreset,
    getSinglePresetImage,
    irisCamera,
    autoiris,
    handleIrisChange,
    handleIrisChangeCommitted,
    toggleAutoiris,
    gain,
    setGain,
    gainArray,
    gainCamera,
    commandProgress,
    wb,
    setWb,
    wbArray,
    wbCamera,
    shutter,
    setShutter,
    shutterCamera,
    shutterArray,
    turnLight1On,
    turnLight1Off,
    turnLight2On,
    turnLight2Off,
    turnRail,
    lightDim,
    lightTemp,
    setLightDim,
    setLightTemp,
    handleLightDimChange,
    handleLightDimChangeCommitted,
    handleLightTempChange,
    handleLightTempChangeCommitted
  };
};
