import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DeleteForever, VpnKey } from "@mui/icons-material";
import React from "react";
import { withRouter } from "react-router-dom";
import { styleSettings } from "./styleSettings";
import { useSettings } from "./useSettings";




function Settings(props) {
  const classes = styleSettings();
  const { id, email, company, industry, branch, country, passwordDeleteModalOpen, handlePasswordDeleteModalClickOpen, handlePasswordDeleteModalClose, deleteUser } = useSettings(props);
  

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      <div>

        {/* Section title */}
        <Typography variant="h5" className={classes.pagetitle}>
          Account settings
        </Typography>
        <Divider className={classes.divider} /><br />


        <Typography>Email address: <strong>{email}</strong></Typography>
        <Typography>Company: <strong>{company}</strong></Typography>
        <Typography>Industry: <strong>{industry}</strong></Typography>
        <Typography>Branch: <strong>{branch}</strong></Typography>
        <Typography>Country:
          <strong>
            {country === "" ? " " + country : " Not specified"}
          </strong>
        </Typography><br />

        <Divider className={classes.divider} /><br />

        <Grid>
        <Grid item>
        <Button
          href="/reset"
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<VpnKey />}
          // onClick={handleNewBookingOpen}
        >
          Reset password
        </Button>
        </Grid>
        <br />
        <Grid item>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<DeleteForever />}
          onClick={handlePasswordDeleteModalClickOpen}
        >
          Delete account
        </Button>

        <Dialog
        open={passwordDeleteModalOpen}
        onClose={handlePasswordDeleteModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your account and all its data will be permanently deleted and your email address will be available for registration again. This action cannot be reverted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordDeleteModalClose} autoFocus>Cancel</Button>
          {id !== 0 &&

          <Button onClick={() => deleteUser(props.user.id)} variant="contained"
          color="secondary">
            DELETE
          </Button>
          }
          
        </DialogActions>
      </Dialog>
        </Grid>

        </Grid>
        




      </div>

    </Box>
  );
}

export default withRouter(Settings);
