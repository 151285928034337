
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

const styleSlotForm = makeStyles((theme) => ({


    slot: {
        // height: 10,
    },
    slotbutton: {
        width: 42,
        height: 200,
        marginRight: 5,
    },

    slotbuttonmobile: {
        width: 125,
        height: 25,
        marginBottom: 5,
        marginRight: 5,
        // margin: 5
    },

    slotactionarea: {
        height: "100%",
        backgroundColor: "lightgrey",
    },
    slotactionareaselected: {
        height: "100%",
        backgroundColor: "green"
    },
    slotactionareataken: {
        height: "100%",
        backgroundColor: "darkred"
    },
    slotactionareadisabled: {
        height: "100%",
        backgroundColor: "darkgrey"
    },
    slotminus: {
        marginTop: -20,
        marginBottom: -20,
    },

    pickertext: {
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            fontSize: 18
        }
    },
    selectionlabel: {
        [theme.breakpoints.down('md')]: {
            fontSize: 14
        }
    },
    slotbox: {
        width: "100%",
    },
    gridList: {
        flexWrap: 'nowrap',
        height: theme.spacing(40),
        // transform: 'translateZ(0)',
        width: "100%",
        overflow: "auto",
        
    },
    gridListmobile: {
        height: theme.spacing(40),
        width: "100%",
        overflow: "auto",
    },
    slotlabel: {

    },

    slotlabelmobile: {
        fontSize: 10,
        marginBottom: 5,
    }
}));

export { styleSlotForm };