import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import React from "react";
import { styleReview } from "./styleReview";
import { useReview } from "./useReview";

export default function Review(props) {
  const classes = styleReview();
  const { getSlotStartTimeString, getSlotEndTimeString } = useReview();


  return (
    <React.Fragment>
      <Grid container direction="column" alignItems="center">
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          className={classes.pickertext}
        >
          Review your booking
        </Typography>

        <Paper variant="outlined" className={classes.root}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.groot}
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                align="center"
              >
                Date
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                align="center"
                className={classes.datatext}
              >
                {props.selectedDate.toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                align="center"
              >
                Time
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                align="center"
                className={classes.datatext}
              >
                {getSlotStartTimeString(
                  props.selectedSlots.sort(
                    (a, b) => a.getTime() - b.getTime()
                  )[0]
                )}{" "}
                –{" "}
                {getSlotEndTimeString(
                  props.selectedSlots.sort((a, b) => a.getTime() - b.getTime())[
                    props.selectedSlots.length - 1
                  ]
                )}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {props.bookingError ? 
          <Alert severity="error">
            {props.bookingError}
        </Alert>
          :
          <Alert severity="warning">
            When you submit your booking, the slots will be reserved and can not be booked by others anymore.
        </Alert>
        }
        
      
      </Grid>
    </React.Fragment>
  );
}
