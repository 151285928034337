import React from 'react';
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import Admin from "../Admin/Admin";
import BookingOverview from "../Booking/BookingOverview/BookingOverview";
import Cameras from "../Cameras/Cameras";
import Settings from "../Settings/Settings";
import Start from "../Start/Start";
import Tour from "../Tour/Tour";
import WaitingRoom from "../Tour/WaitingRoom/WaitingRoom";

function Content(props) {
  let { path } = useRouteMatch();

  return (
    <div>


      <Switch>
        <Route exact path={path}>
          <Start user={props.user} />
        </Route>
        <Route path={`${path}/booking`}>
          <BookingOverview />
        </Route>
        <Route path={`${path}/admin`}>
          <Admin />
        </Route>
        <Route path={`${path}/cameras`}>
          <Cameras />
        </Route>
        <Route path={`${path}/tour`}>
          <Tour />
        </Route>
        <Route path={`${path}/waitingroom`}>
          <WaitingRoom />
        </Route>
        <Route path={`${path}/settings`}>
          <Settings user={props.user}/>
        </Route>
      </Switch>
    </div>
  );
}

export default withRouter(Content);
