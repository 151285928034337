import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import logo from "../../../../shared/logo.svg";
import BottomLinks from "../../BottomLinks/BottomLinks";
import { styleSigning } from "../../styleSigning";
import { useUpdatePassword } from "./useUpdatePassword";

function UpdatePassword(props) {
    const classes = styleSigning();
    const { state, handleChange, handleSubmit, updatePasswordResponse } = useUpdatePassword(props);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Box component="span" m={3}>
                        <div className={classes.paper2}>
                            <img src={logo}  className={classes.logoimage}  alt="Panasonic Connect Europe Logo"/>
                            <div className={classes.logotext}>Virtual Demo Room</div>
                        </div>
                    </Box>
                    <Typography className={classes.titletext}>
                        Reset password
                    </Typography>
                    <form className={classes.form}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={state.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password_confirm"
                                label="Confirm password"
                                type="password"
                                id="passwordConfirm"
                                autoComplete="current-password"
                                value={state.passwordConfirm}
                                onChange={handleChange}
                            />
                        </Grid>

                        {updatePasswordResponse &&
                            <Alert severity="error" className={classes.form}>
                                {/* <AlertTitle>Successfully signed up</AlertTitle> */}
                                {/* We sent you an email to <strong>verify your email</strong> address. */}
                                {updatePasswordResponse}
                            </Alert>
                        }

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}

                        >
                            Update password
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/login" variant="body2" className={classes.bottomnav}>
                                    Back to sign in
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2" className={classes.bottomnav}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
            <BottomLinks />
        </Box>
    );
}

export default withRouter(UpdatePassword);
