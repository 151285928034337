import { makeStyles } from "@mui/styles";
import { green, grey, orange, red } from "@mui/material/colors";

const styleStart = makeStyles((theme) => ({
  root: {
    width: "65%",
  },
  croot: {
    padding: 10,
    height: 270,
    [theme.breakpoints.down('md')]: {
      height: 220,
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },

  media: {
    // height: 140,
    backgroundColor: "white",
  },
  topspace: {
    marginTop: 20,
  },
  startbutton: {
    color: "white",
    background: green[500],
  },
  waitbutton: {
    color: "white",
    background: orange[500],
  },
  
  productimage: {
    height: 110,
    [theme.breakpoints.down('md')]: {
      height: 80,
    },
  },
  productdescription: {
    height: 50,
    [theme.breakpoints.down('md')]: {
      height: 30,
    },
  },
  pagetitle: {
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      fontSize: 20,
    },
  },
  adminPaper: {
    backgroundColor: "#30302e",
    // padding: 10,
    color: "white",
    marginTop: 5,
    marginBottom: 20,
    // maxHeight: "600px",
  },
  
  // divider: {
  //   marginTop: 40,
  //   marginBottom: 40,
  // },
  onlinechip: {
    color: green[500],
  },
  standbychip: {
    color: orange[500],
  },
  homePaperItem: {
    height: 130,
    width: 180,
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 10,
    marginBottom: 10,
    
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  currentTour: {
    backgroundColor: green[700],
  },
  upcomingTour: {
    backgroundColor: orange[500],
  },

  manageBookings: {
    backgroundColor: grey[700],
  },
  createBooking: {
    backgroundColor: theme.palette.primary.main,
  },
  paperItemIcon: {
    textAlign: "center",
    color: "white",
    width: 35,
    height: 35,
    marginBottom: 5,
  },
  paperItemText: {
    color: "white",
    marginTop: 5,
  },
}));

export { styleStart };
