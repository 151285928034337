import { Box, Button, Container, Grid } from "@mui/material";
import React from 'react';

function BottomLinks(props) {
    // const classes = styleBottomLinks();

    return (
        // <Grid container direction="row"
        //     justifyContent="center"
        //     alignItems="center" class={classes.linksgrid}>
        //     <Grid item>
        //         <div class={classes.link}>
        //             <a href="https://business.panasonic.de/impressum" target="_blank">
        //             {/* <Link target={"_blank"} to="https://business.panasonic.de/impressum" variant="body2"> */}
        //                 Imprint
        //             {/* </Link> */}
        //             </a>
        //         </div>
        //     </Grid>
        //     <Grid item>
        //         <div class={classes.link}>
        //         <a href="https://business.panasonic.de/datenschutzrichtlinie" target="_blank">
        //             {/* <Link target={"_blank"} to="https://business.panasonic.de/datenschutzrichtlinie" variant="body2"> */}
        //                 GDPR
        //             {/* </Link> */}
        //             </a>
        //         </div>
        //     </Grid>


        // </Grid>
        <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
        }}
      >
        <Container maxWidth="sm">
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={5}
            >
                <Grid item>
                <Button variant="text" color="primary" href="https://eu.connect.panasonic.com/de/de/ueber-diese-seite/impressum">Imprint</Button>
                </Grid>
                <Grid item>
                    <Button variant="text" color="primary" href="https://eu.connect.panasonic.com/gb/en/privacy-policy">Privacy Policy</Button>
                </Grid>
                <Grid item>
                    <Button variant="text" color="primary" href="https://eu.connect.panasonic.com/gb/en/terms-conditions">Terms & Conditions</Button>
                </Grid>
            </Grid>
        </Container>
      </Box>
        
    );

}

export default BottomLinks;