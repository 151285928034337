import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { Alert, AlertTitle } from "@mui/lab";
import React from "react";
import { withRouter } from "react-router-dom";
import logo from "../../../shared/logo.svg";
import BottomLinks from "../BottomLinks/BottomLinks";
import { styleEmailConfirm } from "./styleEmailConfirm";

function EmailConfirm(props) {
  const classes = styleEmailConfirm();
  // const {} = useEmailConfirm(props);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Box component="span" m={3}>
          <div className={classes.paper2}>
            <img src={logo}  className={classes.logoimage} alt="Panasonic Logo" />
            <div className={classes.logotext}>Virtual Demo Room</div>
          </div>
        </Box>
        <Typography className={classes.titletext}>
          Registration
        </Typography>
      </div>
      <Alert severity="success" className={classes.form}>
        <AlertTitle>Successfully signed up</AlertTitle>
        We sent you an email to <strong>verify your email</strong> address.
      </Alert>
    </Container>
    <BottomLinks />
    </Box>
  );
}

export default withRouter(EmailConfirm);
