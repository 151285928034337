import { Button, Chip, Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import ClearIcon from '@mui/icons-material/Clear';
import TableRow from '@mui/material/TableRow';
// import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import moment from 'moment';
import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { useAdmin } from "./useAdmin";
import { styleBookingOverview } from "../Booking/BookingOverview/styleBookingOverview";

function AllBookings(props) {
    const classes = styleBookingOverview();
    const { allBookings } = useAdmin(props);

    useEffect(() => {
        console.log(allBookings)
    }, [allBookings])

    return (


        <div>
            {/* All upcoming bookings table */}
            {allBookings.length > 0 &&
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Status
                                </TableCell>
                                <TableCell>
                                    User email
                                </TableCell>
                                <TableCell>
                                    Date
                                </TableCell>
                                <TableCell>
                                    Time
                                </TableCell>
                                <TableCell>
                                    Duration
                                </TableCell>
                                {/* <TableCell>
                                    Cancel tour
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allBookings.map((booking) => {
                                if (booking !== undefined) {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={booking[0]}>
                                            <TableCell >
                                                {booking[3] === true ?
                                                    <Chip variant="outlined" size="small" label="Cancelled" className={`${classes.cancelchip} ${classes.statuschip}`} />
                                                    :
                                                    (moment(booking[1]).isBefore(moment()) ?
                                                        <Chip variant="outlined" size="small" label="Completed" className={`${classes.cancelchip} ${classes.statuschip}`} />
                                                        :
                                                        <Chip variant="outlined" size="small" label="Confirmed" className={`${classes.confirmedchip} ${classes.statuschip}`} />
                                                    )

                                                }
                                            </TableCell>

                                            <TableCell >
                                                {booking[4]}
                                            </TableCell>

                                            <TableCell >
                                                {moment(booking[1]).format("MMMM Do YYYY")}
                                            </TableCell>

                                            <TableCell >
                                                {moment(booking[1]).format("HH:mm")}
                                            </TableCell>

                                            <TableCell >
                                                {30 * booking[2].length} minutes
                                            </TableCell>
                                            {/* <TableCell >
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    className={classes.cancelbutton}
                                                    // onClick={() => handleOpenCancelDialog(booking[0])}
                                                    disabled={booking[3] === true}
                                                    color="secondary"
                                                >
                                                    <ClearIcon />
                                                </Button>

                                            </TableCell> */}
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                    <Dialog
                        // open={openCancelDialog}
                        // onClose={handleCloseCancelDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure you want to cancel this tour?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                If you cancel this tour, your reserved slots can be booked by others again.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                //  onClick={handleCloseCancelDialog} 
                                color="primary">
                                No
                            </Button>
                            <Button
                                //  onClick={() => handleCancelClick()} 
                                color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </TableContainer>
            }

        </div>



    );
}

export default withRouter(AllBookings);
