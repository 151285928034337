import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../shared/constants/constants";
import { useServerApi } from "../../shared/controller/useServerApi";

export const useDashboard = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();
  const { logout, redirectToWaitingRoom, redirectToStart, redirectToBooking, redirectToAdmin,
    redirectToTour, redirectToSettings } = useServerApi(props);

  const [showCookieConsent, setShowCookieConsent] = useState(false);
  // const [agreedToCookies, setAgreedToCookies] = useState(false);

 

  const checkIfUserAgreedToCookies = () => {
    if (sessionStorage.getItem("cookie_consent") === null) {
      setShowCookieConsent(true);
      //show consent
    } else if (sessionStorage.getItem("cookie_consent") === "agreed") {
      //dont show consent
      //move sessionStorage to localStorage 
    } else if (sessionStorage.getItem("cookie_consent") === "disagreed") {
      //dont show consent
    }
  }

  const handleCookieAgree = () => {
    sessionStorage.setItem("cookie_consent", "agreed");
    setShowCookieConsent(false);
  }

  const handleCookieDisagree = () => {
    sessionStorage.setItem("cookie_consent", "disagreed");
    setShowCookieConsent(false);
  }

  const getUserDetails = useCallback(() => {
    axios
      .get(API_BASE_URL + "user", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then((response) => {
        if (response.data !== "") {
          setUser(response.data);
        } else {
          // token expired
          logout();
        }

      })
      .catch((error) => {
        // console.log(error);
        // logout();
      });
  }, [logout]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };





  const handleLogout = () => {
    logout();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConsentClose = () => {
    setShowCookieConsent(false);
  };

  useEffect(() => {
    axios
      .get(API_BASE_URL + "user", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then((response) => {
        if (response.data !== "") {
          setUser(response.data);
        } else {
          // token expired
          logout();
        }

      })
      .catch((error) => {
        // console.log(error);
        // logout();
      });
  }, []);

  return {
    open,
    setOpen,
    handleDrawerOpen,
    handleDrawerClose,
    redirectToAdmin,
    redirectToBooking,
    redirectToStart,
    handleLogout,
    user,
    anchorEl,
    handleClick,
    handleClose,
    redirectToWaitingRoom,
    redirectToTour,
    showCookieConsent,
    handleConsentClose,
    handleCookieAgree,
    handleCookieDisagree,
    redirectToSettings
  };
};
