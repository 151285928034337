import { useEffect } from "react";

export const useDateForm = (props) => {
  
  const handleDateChange = (date) => {
    // console.log(date);
    date.setHours(0, 0, 0, 0, 0, 0);
    props.setSelectedDate(date);

    
  };

  useEffect(() => {
    //Test if selected date has been defined (fixed bug)
    if (typeof props.selectedDate.toDateString != typeof Function) {
      const date = new Date();
      date.setUTCHours(0, 0, 0);
      props.setSelectedDate(date);
    }
  }, [props]);
  return { handleDateChange };
};
