import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import logo from "../../../shared/logo.svg";
import BottomLinks from "../BottomLinks/BottomLinks";
import { styleSigning } from "../styleSigning";
import { usePasswordReset } from "./usePasswordReset";

function PasswordReset(props) {
    const classes = styleSigning();
    const { email, handleEmailChange, handleEmailSubmit, resetResponse } = usePasswordReset(props);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box component="span" m={3}>
                    <div className={classes.paper2}>
                        <img src={logo}  className={classes.logoimage}  alt="Panasonic Connect Europe Logo"/>
                        <div className={classes.logotext}>Virtual Demo Room</div>
                    </div>
                </Box>
                <Typography className={classes.titletext}>
                    Reset password
                </Typography>
                <form className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                    />

                    {resetResponse &&
                        <Alert severity="error" className={classes.form}>
                            {/* <AlertTitle>Successfully signed up</AlertTitle> */}
                            {/* We sent you an email to <strong>verify your email</strong> address. */}
                            {resetResponse}
                        </Alert>
                    }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleEmailSubmit}
                    // disabled
                    >
                        Send reset email
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" variant="body2" className={classes.bottomnav}>
                                Back to sign in
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/register" variant="body2" className={classes.bottomnav}>
                                {"Don't have an account? Register here"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                
            </div>
        </Container>
        <BottomLinks />
        </Box>
    );
}

export default withRouter(PasswordReset);
