import { Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
// import { styleCameras } from "./styleCameras";
import { useCameras } from "./useCameras";
import SingleCamera from "../SingleCamera/SingleCamera";
import React from 'react';


function Cameras(props) {
  // const classes = styleCameras();
  const { cameras } = useCameras();

  return (
    <Grid container spacing={3}>
      {cameras
        // .sort((a, b) => a.timeCreated < b.timeCreated)
        .map((camera) => {
          return (
            <Grid item xs={12} sm={6} lg={3}>
              <SingleCamera camera={camera} />
            </Grid>
          );
        })}
    </Grid>
  );
}

export default withRouter(Cameras);
