import React from 'react';
import { withRouter } from "react-router-dom";
import CameraDetail from "./CameraDetail/CameraDetail";
import { useTour } from "./useTour";

function Tour(props) {
  // const classes = styleTour();
  const {
    finished,
    setFinished,
    cameras,
    setCameras,
    selectedCamera,
    setSelectedCamera,
    selectedQuality,
    setSelectedQuality,
    // bookingIsCurrent,
    canStartTour,
    // redirectToWaitingRoom,
    isAdmin
  } = useTour(props);

  if (canStartTour) {
    // if (finished) {

    //   // console.log(bookingIsCurrent);
      return (
        <CameraDetail
          cameras={cameras}
          selectedCamera={selectedCamera}
          setSelectedCamera={setSelectedCamera}
          finished={finished}
          setFinished={setFinished}
          selectedQuality={selectedQuality}
          setSelectedQuality={setSelectedQuality}
          setCameras={setCameras}
          isAdmin={isAdmin}
        />
      );


    // } else {
    //   return (
    //     <PrepareCameraDetail
    //       setFinished={setFinished}
    //       cameras={cameras}
    //       setCameras={setCameras}
    //       selectedCamera={selectedCamera}
    //       setSelectedCamera={setSelectedCamera}
    //       selectedQuality={selectedQuality}
    //       setSelectedQuality={setSelectedQuality}
    //     />
    //   );
    // }
  } else {
    return (
      <div>
        No permission to start the tour.


      </div>

    );

  }

}

export default withRouter(Tour);
