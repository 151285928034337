import { makeStyles } from "@mui/styles";

const styleSingleCameraSmall = makeStyles((theme) => ({
  croot: {
    
    // padding: 20,
    // height: 120,
    // [theme.breakpoints.down("xs")]: {
    //   height: 60,
    // },
    // width: 120,
    // margin: 20,
    // backgroundColor: "#161618",
    // color: "#9c9c9c",
    
  },
  productimage: {
    height: 50,
    [theme.breakpoints.down('md')]: {
      height: 30,
    },
  },
  
}));

export { styleSingleCameraSmall };
