import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import {
  Box, LinearProgress,
  Paper,
  Slider
} from "@mui/material";
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../../shared/constants/constants";
import SingleCameraSmall from "../../SingleCameraSmall/SingleCameraSmall";
import { styleCameraDetail } from "./styleCameraDetail";
import { useCameraDetail } from "./useCameraDetail";
import VideoPlayer from "./VideoPlayer";
// import { Replay } from 'vimond-replay';
// import 'vimond-replay/index.css';
// import CompoundVideoStreamer from 'vimond-replay/video-streamer/compound';
import { Alert } from "@mui/material";
import React from 'react';
import PrepareCameraDetail from "../PrepareCameraDetail/PrepareCameraDetail";


function CameraDetail(props) {
  const classes = styleCameraDetail();
  const {
    zoom,
    focus,
    handleZoomChange,
    handleZoomChangeCommitted,
    handleFocusChange,
    handleFocusChangeCommitted,
    handleSelectedCameraChange,
    handleSelectedQualityChange,
    zoomCamera,
    focusCamera,
    autofocus,
    toggleAutofocus,
    selectedPreset,
    goToPreset,
    setPreset,
    iris,
    autoiris,
    handleIrisChange,
    handleIrisChangeCommitted,
    irisCamera,
    toggleAutoiris,
    gain,
    gainCamera,
    commandProgress,
    wb,
    wbArray,
    wbCamera,
    turnLight1On,
    turnLight1Off,
    turnLight2On,
    turnLight2Off,
    lightDim,
    lightTemp,
    handleLightDimChange,
    handleLightDimChangeCommitted,
    handleLightTempChange,
    handleLightTempChangeCommitted
  } = useCameraDetail(props);

  const mainStreamUrl = API_BASE_URL + "cameras/stream/" + props.selectedCamera.id;
  const mainStreamOptions = {
    autoplay: true,
    controls: true,
    sources: [
      {
        src: mainStreamUrl,
        // src: "http://192.168.0.10/cgi-bin/mjpeg?stream=1",
        type: "application/x-mpegURL",
      },
    ],
    fluid: true,
    preload: 'auto',
    muted: true,
  };

  // const previewStreamUrl = "http://192.168.0.10/cgi-bin/mjpeg?stream=4";
  // const previewStreamUrl = API_BASE_URL + "cameras/preview/" + props.selectedCamera.id;
  // const previewStreamOptions = {
  //   autoplay: true,
  //   controls: true,
  //   sources: [
  //     {
  //       src: previewStreamUrl,
  //       // src: "http://192.168.0.10/cgi-bin/mjpeg?stream=1",
  //       type: "application/x-mpegURL",
  //     },
  //   ],
  //   fluid: true,
  //   preload: 'auto',
  //   muted: true,
  // };



  function dimSliderValueText(value) {
    return `${value}%`;
  }

  function tempSliderValueText(value) {
    return `${2500 + 740 * (value/10)}K`;
  }








  const presetArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const wbLabelArray = ["ATW", "AWB A", "AWB B", "ATW", "3200K", "5600K", "", "", "", "VAR"];
  const gainLabelDict = {
    0: "0db",
    3: "3db",
    6: "6db",
    9: "9db",
    12: "12db",
    15: "15db",
    18: "18db",
    120: "Auto"
  }


  return (
    <div>
      <Grid container spacing={1}>

        <Grid item sm={12} md={9}>
          {/* Videoplayer */}
          <Paper className={classes.videopaper}>
            {props.finished ? (
              <div className={classes.cameraPaper}>

                <VideoPlayer {...mainStreamOptions} />
              </div>
            ) : (
              <div className={classes.prepareCameraPaper}>
                <PrepareCameraDetail
                  setFinished={props.setFinished}
                  cameras={props.cameras}
                  setCameras={props.setCameras}
                  selectedCamera={props.selectedCamera}
                  setSelectedCamera={props.setSelectedCamera}
                  selectedQuality={props.selectedQuality}
                  setSelectedQuality={props.setSelectedQuality}
                  style={{ maxHeight: 300 }}
                />
              </div>
            )



            }
          </Paper>







          {/* <iframe src="https://192.168.199.98:8443/" width="100%" height="600"/> */}

          {/* <video width="100%" height="100%" controls muted="muted" autoplay="autoplay" preload="auto" class="video-js vjs-default-skin">
          <source src={API_BASE_URL + "cameras/stream/" + props.selectedCamera.id} type="application/x-mpegURL" />

          Your browser does not support the video tag.
        </video> */}

          {/* <Replay
          source={API_BASE_URL + "cameras/stream/" + props.selectedCamera.id}
          initialPlaybackProps={{ isPaused: false }}
        >
          <HlsjsVideoStreamer />
        </Replay> */}

          {/* <Replay
          width="100%" height="100%"
          source={{
            streamUrl: API_BASE_URL + "cameras/stream/" + props.selectedCamera.id,
            contentType: 'application/x-mpegurl',
          }}
          initialPlaybackProps={{ isPaused: false }}
        >
          <CompoundVideoStreamer />
        </Replay> */}

          <Paper className={classes.commandPaper}>
            <Typography
              variant="caption"
              className={classes.slidercaption}

            >

              {commandProgress === 0 && "Waiting for command"}
              {commandProgress === 5 && "Sending command..."}
              {commandProgress === 35 && "Command successfully sent to server"}
              {commandProgress === 55 && "Response good, command successfully executed"}
              {commandProgress === 75 && "Waiting for stream update..."}
              {commandProgress === 100 && "Camera stream updated"}
            </Typography>
            <LinearProgress variant="determinate" value={commandProgress} />
          </Paper>



          {/* Quality selection */}
          <Paper className={classes.tabs}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Resolution</Typography>
            </Paper>
            <Grid container direction="row" justifyContent="space-evenly">

              {/* 1920 x 1080 */}
              <Grid sm={5}  item className={props.selectedQuality === 1 ? classes.singleQualitySelected : classes.singleQuality}>
                <div
                  onClick={() => {
                    if (props.finished) {
                      handleSelectedQualityChange(1);
                    }
                  }}

                >
                  1920 x 1080
                  {props.selectedQuality === 1 ?
                    <Paper className={classes.selectedPaper}></Paper>
                    :
                    <Paper className={classes.unSelectedPaper}></Paper>
                  }
                </div>

              </Grid>

              {/* 1280 x 720 */}
              {props.selectedCamera.id !== 7 &&
              <Grid sm={5} item className={props.selectedQuality === 2 ? classes.singleQualitySelected : classes.singleQuality}>
              <div
                onClick={() => {
                  if (props.finished) {
                    // if (props.selectedCamera.id !== 7) {
                      handleSelectedQualityChange(2);
                    // }
                  }

                }}

              >
                1280 x 720
                {props.selectedQuality === 2 ?
                  <Paper className={classes.selectedPaper}></Paper>
                  :
                  <Paper className={classes.unSelectedPaper}></Paper>
                }
              </div>

            </Grid>
              
              }
              

              {/* 640 x 360 */}
              {/* <Grid item className={props.selectedQuality === 3 ? classes.singleQualitySelected : classes.singleQuality}>
                <div
                  onClick={() => {
                    if (props.finished) {
                      if (props.selectedCamera.id !== 7) {
                        handleSelectedQualityChange(3);
                      }
                    }
                  }}

                >
                  640 x 360
                  {props.selectedQuality === 3 ?
                    <Paper className={classes.selectedPaper}></Paper>
                    :
                    <Paper className={classes.unSelectedPaper}></Paper>
                  }
                </div>

              </Grid> */}

              {/* 320 x 180 */}
              {/* <Grid item className={props.selectedQuality === 4 ? classes.singleQualitySelected : classes.singleQuality}>
                <div
                  onClick={() => {
                    if (props.finished) {
                      if (props.selectedCamera.id !== 7) {
                        handleSelectedQualityChange(4);
                      }
                    }
                  }}

                >
                  320 x 180
                  {props.selectedQuality === 4 ?
                    <Paper className={classes.selectedPaper}></Paper>
                    :
                    <Paper className={classes.unSelectedPaper}></Paper>
                  }
                </div>

              </Grid> */}

            </Grid>
          </Paper>



          {/* Camera selection */}
          <Paper className={classes.tabs}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Camera model</Typography>
            </Paper>
            <Grid container direction="row" justifyContent="space-between">
              {props.cameras.filter(c => c.status === "ONLINE").map((camera, index) => (
                <Grid item className={props.selectedCamera.id !== camera.id ? classes.singleCamera : classes.singleCameraSelected}>
                  <div
                    onClick={() => {
                      if (props.finished) {
                        handleSelectedCameraChange(index);
                      }
                    }}

                  >
                    <SingleCameraSmall
                      camera={camera}
                    />


                    {props.selectedCamera.id === camera.id ?
                      <Paper className={classes.selectedPaper}></Paper>
                      :
                      <Paper className={classes.unSelectedPaper}></Paper>
                    }
                  </div>

                </Grid>
              ))}
            </Grid>
          </Paper>


        </Grid>
        {/* <Grid item sm={12} md={3}> */}
        {/* <VideoPlayer {...previewStreamOptions} /> */}
        {/* <video src={previewStreamUrl} height="200" controls/> */}
        {/* </Grid> */}
        <Grid item sm={12} md={3}>


          {/* <Grid item sm={12}> */}


          {/* </Grid> */}

          {/* Light */}
          {/* <Paper className={classes.tabPaper}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Light</Typography>
            </Paper>
            <Paper className={classes.paper} variant="outlined" square>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Grid item>
                  <ButtonGroup className={classes.buttongroup} aria-label="outlined secondary button group">
                    <Button><Typography className={classes.lightbutton}>On</Typography></Button>
                    <Button><Typography className={classes.lightbutton}>Off</Typography></Button>
                    <Button><Typography className={classes.lightbutton}>Low</Typography></Button>
                    <Button><Typography className={classes.lightbutton}>Mid</Typography></Button>
                    <Button><Typography className={classes.lightbutton}>High</Typography></Button>
                  </ButtonGroup>
                </Grid>

              </Grid>


            </Paper>
          </Paper> */}

          {/* Autotracking */}
          {/* <Paper className={classes.tabPaper}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Autotracking</Typography>
            </Paper>
            <Paper className={classes.paper} variant="outlined" square>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Grid item>
                  <ButtonGroup className={classes.buttongroup} aria-label="outlined secondary button group">
                    <Button><Typography className={classes.lightbutton}>On</Typography></Button>
                    <Button><Typography className={classes.lightbutton}>Off</Typography></Button>
                  </ButtonGroup>
                </Grid>

              </Grid>


            </Paper>
          </Paper> */}

          {/* Preset */}
          <Paper className={classes.tabPaper}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Preset</Typography>
            </Paper>
            <Paper className={classes.paper} variant="outlined" square>

              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                {props.selectedCamera.presetimages !== undefined &&
                  props.selectedCamera.presetimages.slice(0, 10).map((presetId, i) => (

                    <Grid item>
                      <div
                        className={selectedPreset === i ? classes.presetbuttonselected : classes.presetbutton}
                        onClick={() => {

                          goToPreset(i);

                        }}

                      >
                        <img
                          // src={`${API_BASE_URL + "cameras/presetimage/" + presetId + "?" + Math.floor(Date.now() / 1000)}`} width="100%"
                          src={`${API_BASE_URL + "cameras/presetimage/" + presetId + "?token=" + (sessionStorage.getItem(ACCESS_TOKEN_NAME))}`} width="100%"
                          alt={`${"Preset " + i}`}
                        />
                        {/* <img src="" height="90" width="90" style={{borderWidth: 0}}/> */}
                      </div>
                    </Grid>
                  ))


                }
                {props.selectedCamera.presetimages !== undefined &&
                  presetArray.slice(props.selectedCamera.presetimages.length).map((i) => (

                    <Grid item>

                      <div
                        justify
                        className={selectedPreset === i ? classes.presetbuttonselected : classes.presetbutton}
                        onClick={() => {

                          goToPreset(i);

                        }}

                      >
                        {/* <img src="" width="100%"/> */}
                        <img src={`${Math.floor(Date.now() / 1000)}`} alt={`${"Preset " + i + 1}`} height="40" width="60" style={{ borderWidth: 0 }} />
                      </div>
                    </Grid>
                  ))}
              </Grid>
              { props.isAdmin &&
                <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <Paper
                  size="small"
                  className={classes.setpresetbutton}
                  onClick={() => {
                    setPreset(selectedPreset);
                  }}
                  variant="outlined"
                  square
                >
                  Set
                </Paper>
              </Grid>
          }

            </Paper>
          </Paper>

          {/* Pan/Tilt */}
          <Paper className={classes.tabPaper}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Lens Control</Typography>
            </Paper>
            <Paper className={classes.paper} variant="outlined" square>
              <Grid container justifyContent="space-evenly" alignItems="center" spacing={2}>
                {/* Speed */}
                {/* <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="space-evenly"
                  alignItems="left"
                >
                  <Grid item>
                    <Typography
                      variant="caption"
                      className={classes.slidercaption}
                    >
                      Speed
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          alignItems="center"

                        >

                          <Grid item>
                            <Paper
                              size="small"
                              className={classes.topSliderButton}
                              onClick={() => {
                                setSpeed(100);
                              }}
                              variant="outlined"
                              square
                            >
                              Fast
                            </Paper>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" className={classes.slidertext}>{speed}</Typography>
                          </Grid>
                          <Grid item>
                            <Paper
                              size="small"
                              className={classes.bottomSliderButton}
                              onClick={() => {
                                setSpeed(0);
                              }}
                              square
                            >
                              Slow
                            </Paper>
                          </Grid>


                        </Grid>
                      </Grid>

                      <Grid item>
                        <Slider
                          orientation="vertical"
                          // getAriaValueText={valuetext}
                          defaultValue={speed}
                          aria-labelledby="vertical-slider"
                          className={classes.slider}
                          value={speed}
                          onChange={handleSpeedChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Divider orientation="vertical" className={classes.controlDivider} /> */}

                {/* Control buttons */}
                {/* <Grid item>
                <Grid
                  container
                  direction="row"
                  // justify="space-between"
                  alignItems="center"
                > */}
                {/* <Grid item>
                    <Typography
                      variant="caption"
                      className={classes.slidercaption}
                    >
                      Control
                    </Typography>
                  </Grid> */}
                {/* <Grid item xs>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      // size="small"
                      onMouseDown={() => {
                        turnCamera("upleft");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptuplefticon} />
                    </Paper>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      size="small"
                      onMouseDown={() => {
                        turnCamera("left");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptlefticon} />
                    </Paper>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      size="small"
                      onMouseDown={() => {
                        turnCamera("downleft");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptdownlefticon} />
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      size="small"
                      onMouseDown={() => {
                        turnCamera("up");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptupicon} />
                    </Paper>
                    <Paper disabled className={classes.ptmiddlebutton} size="small">
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        // {/* Pan/Tilt */}
                {/* </Typography>
                    </Paper>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      size="small"
                      onMouseDown={() => {
                        turnCamera("down");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptdownicon} />
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      size="small"
                      onMouseDown={() => {
                        turnCamera("upright");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptuprighticon} />
                    </Paper>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      size="small"
                      onMouseDown={() => {
                        turnCamera("right");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptrighticon} />
                    </Paper>
                    <Paper
                      disableElevation
                      className={classes.ptbutton}
                      size="small"
                      onMouseDown={() => {
                        turnCamera("downright");
                      }}
                      onMouseUp={() => {
                        stopTurn();
                      }}
                      align="center"
                      square
                    >
                      <ArrowLeftIcon className={classes.ptdownrighticon} />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <Divider orientation="vertical" className={classes.controlDivider} /> */}

                {/* Zoom */}
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="left"
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        Zoom
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"

                          >

                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.topSliderButton}
                                onClick={() => {
                                  zoomCamera(zoom + 5);
                                }}
                                variant="outlined"
                                square
                              >
                                T
                              </Paper>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" className={classes.slidertext}>{zoom}</Typography>
                            </Grid>
                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.bottomSliderButton}
                                onClick={() => {
                                  zoomCamera(zoom - 5);
                                }}
                                square
                              >
                                W
                              </Paper>
                            </Grid>


                          </Grid>
                        </Grid>

                        <Grid item>
                          <Slider
                            orientation="vertical"
                            // getAriaValueText={valuetext}
                            defaultValue={zoom}
                            aria-labelledby="vertical-slider"
                            className={classes.slider}
                            value={zoom}
                            onChange={handleZoomChange}
                            onChangeCommitted={handleZoomChangeCommitted}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {/* Focus */}
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="left"
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        Focus
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"

                          >

                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.topSliderButtonAuto}
                                onClick={() => {
                                  focusCamera(focus + 5);
                                }}
                                variant="outlined"
                                square
                              >
                                Far
                              </Paper>
                            </Grid>
                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.bottomSliderButtonAuto}
                                onClick={() => {
                                  focusCamera(focus - 5);
                                }}
                                square
                              >
                                Near
                              </Paper>
                            </Grid>

                            <Grid item>
                              <Paper
                                square
                                size="small"
                                onClick={() => {
                                  toggleAutofocus(autofocus === 1 ? 0 : 1);
                                }}
                                className={autofocus === 1 ? classes.sliderbuttonselected : classes.sliderbutton}

                              >
                                Auto
                              </Paper>
                            </Grid>

                          </Grid>

                        </Grid>

                        <Grid item>
                          <Slider
                            orientation="vertical"
                            // getAriaValueText={valuetext}
                            defaultValue={focus}
                            aria-labelledby="vertical-slider"
                            className={classes.slider}
                            value={focus}
                            onChange={handleFocusChange}
                            onChangeCommitted={handleFocusChangeCommitted}
                            disabled={autofocus === 1}
                          />
                        </Grid>

                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Paper>

          {/* Light */}
          <Paper className={classes.tabPaper}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Lights</Typography>
            </Paper>
            <Paper className={classes.paper} variant="outlined" square>

              <Grid container justifyContent="space-evenly" alignItems="center" spacing={2}>
                <Grid item>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    <Typography variant="body2" color="white">Deco light 1</Typography>

                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Paper
                        size="small"
                        className={classes.setpresetbutton}
                        onClick={() => {
                          turnLight1On();
                        }}
                        variant="outlined"
                        square
                      >
                        On
                      </Paper>
                      <Paper
                        size="small"
                        className={classes.setpresetbutton}
                        onClick={() => {
                          turnLight1Off();
                        }}
                        variant="outlined"
                        square
                      >
                        Off
                      </Paper>
                    </Grid>


                  </Grid>

                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    <Typography variant="body2" color="white">Deco light 2</Typography>

                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Paper
                        size="small"
                        className={classes.setpresetbutton}
                        onClick={() => {
                          turnLight2On();
                        }}
                        variant="outlined"
                        square
                      >
                        On
                      </Paper>
                      <Paper
                        size="small"
                        className={classes.setpresetbutton}
                        onClick={() => {
                          turnLight2Off();
                        }}
                        variant="outlined"
                        square
                      >
                        Off
                      </Paper>
                    </Grid>


                  </Grid>

                </Grid>

              </Grid>
              <Grid container justifyContent="center" alignItems="center" spacing={2} direction="column">
                <Grid item>
                <Typography variant="body2" color="white">Light dimming</Typography>
                </Grid>
                <Grid item>
                <Box sx={{ width: 240, height: 20 }}>
                  <Slider
                    aria-label="Restricted values"
                    value={lightDim}
                    defaultValue={lightDim}
                    valueLabelFormat={dimSliderValueText}
                    getAriaValueText={dimSliderValueText}
                    step={10}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                    // marks={dimSliderMarks}
                    className={classes.sliderhorizontal}
                    onChange={handleLightDimChange}
                    onChangeCommitted={handleLightDimChangeCommitted}
                  />
                </Box>
                </Grid>
              
                
              </Grid>
              <Grid container justifyContent="center" alignItems="center" spacing={2} direction="column">
                <Grid item>
                <Typography variant="body2" color="white">Light temperature</Typography>
                </Grid>
                <Grid item>
                <Box sx={{ width: 240, height: 20 }}>
                  <Slider
                    aria-label="Restricted values"
                    value={lightTemp}
                    defaultValue={lightTemp}
                    valueLabelFormat={tempSliderValueText}
                    getAriaValueText={tempSliderValueText}
                    step={10}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                    // marks={dimSliderMarks}
                    className={classes.sliderhorizontal}
                    onChange={handleLightTempChange}
                    onChangeCommitted={handleLightTempChangeCommitted}
                  />
                </Box>
                </Grid>
              
                
              </Grid>
              

            </Paper>
          </Paper>

          {/* Rail */}
          {/* <Paper className={classes.tabPaper}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Rail</Typography>
            </Paper>
            <Paper className={classes.paper} variant="outlined" square>

            <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Paper
                        size="small"
                        className={classes.setpresetbutton}
                        onClick={() => {
                          turnRail(0);
                        }}
                        variant="outlined"
                        square
                      >
                        Off
                      </Paper>
                      <Paper
                        size="small"
                        className={classes.setpresetbuttonwider}
                        onClick={() => {
                          turnRail(1);
                        }}
                        variant="outlined"
                        square
                      >
                        Normal
                      </Paper>
                      <Paper
                        size="small"
                        className={classes.setpresetbutton}
                        onClick={() => {
                          turnRail(2);
                        }}
                        variant="outlined"
                        square
                      >
                        Fast
                      </Paper>
                    </Grid>
              

            </Paper>
          </Paper> */}



          {/* Image adjust */}
          <Paper className={classes.tabPaper}>
            <Paper className={classes.papercaption1}>
              <Typography variant="body2">Image adjust</Typography>
            </Paper>
            <Paper className={classes.paper} variant="outlined" square>
              <Grid container justifyContent="space-evenly" alignItems="center" spacing={2}>

                {/* Iris */}
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="left"
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        Iris
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"

                          >

                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.topSliderButtonAuto}
                                onClick={() => {
                                  irisCamera(iris + 5);
                                }}
                                variant="outlined"
                                square
                              >
                                +
                              </Paper>
                            </Grid>
                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.bottomSliderButtonAuto}
                                onClick={() => {
                                  irisCamera(iris - 5);
                                }}
                                square
                              >
                                -
                              </Paper>
                            </Grid>

                            <Grid item>
                              <Paper
                                square
                                size="small"
                                onClick={() => {
                                  toggleAutoiris(autoiris === 1 ? 0 : 1);
                                }}
                                className={autoiris === 1 ? classes.sliderbuttonselected : classes.sliderbutton}

                              >
                                Auto
                              </Paper>
                            </Grid>

                          </Grid>

                        </Grid>

                        <Grid item>
                          <Slider
                            orientation="vertical"
                            // getAriaValueText={valuetext}
                            defaultValue={iris}
                            aria-labelledby="vertical-slider"
                            className={classes.slider}
                            value={iris}
                            onChange={handleIrisChange}
                            onChangeCommitted={handleIrisChangeCommitted}
                            disabled={autoiris === 1}
                          />
                        </Grid>

                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                <Divider orientation="vertical" className={classes.controlDivider} />

                {/* Gain */}
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="left"
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        Gain
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"

                          >

                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.topSliderButton}
                                onClick={() => {
                                  // console.log(gain);
                                  if (gain < 18) {
                                    gainCamera(gain + 3);
                                  } else if (gain === 120) {
                                    gainCamera(0);
                                  } else {
                                    gainCamera(120);
                                  }
                                }}
                                variant="outlined"
                                square
                              >
                                <ArrowLeftIcon className={classes.ptupicon} />
                              </Paper>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" className={classes.slidertext}>{gainLabelDict[gain]}</Typography>
                            </Grid>
                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.bottomSliderButton}
                                onClick={() => {
                                  if (gain < 1) {
                                    gainCamera(120);
                                  } else if (gain === 120) {
                                    gainCamera(18);
                                  } else {
                                    gainCamera(gain - 3);
                                  }
                                }}
                                square
                              >
                                <ArrowLeftIcon className={classes.ptdownicon} />
                              </Paper>
                            </Grid>


                          </Grid>
                        </Grid>


                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {/* WB */}
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="left"
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        WB
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"

                          >

                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.topSliderButton}
                                onClick={() => {
                                  if (wb < 9) {
                                    wbCamera(wbArray[wb + 1]);
                                  } else {
                                    wbCamera(0);
                                  }

                                }}
                                variant="outlined"
                                square
                              >
                                <ArrowLeftIcon className={classes.ptupicon} />
                              </Paper>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" className={classes.slidertext}>{wbLabelArray[wb]}</Typography>
                            </Grid>
                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.bottomSliderButton}
                                onClick={() => {
                                  if (wb < 1) {
                                    wbCamera(9);
                                  } else if (wb === 9) {
                                    wbCamera(5);
                                  } else {
                                    wbCamera(wbArray[wb - 1]);
                                  }
                                }}
                                square
                              >
                                <ArrowLeftIcon className={classes.ptdownicon} />
                              </Paper>
                            </Grid>


                          </Grid>
                        </Grid>


                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {/* <Divider orientation="vertical" className={classes.controlDivider} /> */}

                {/* Shutter */}
                {/* <Grid item>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="left"
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        Shutter
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="center"

                          >

                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.topSliderButton}
                                onClick={() => {
                                  if (shutter < 13) {
                                    shutterCamera(shutterArray[shutter + 1]);
                                  } else {
                                    shutterCamera(0);
                                  }
                                }}
                                variant="outlined"
                                square
                              >
                                <ArrowLeftIcon className={classes.ptupicon} />
                              </Paper>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" className={classes.slidertext}>{shutter}</Typography>
                            </Grid>
                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.bottomSliderButton}
                                onClick={() => {
                                  if (shutter < 1) {
                                    shutterCamera(13);
                                  } else if (wb === 2) {
                                    shutterCamera(0);
                                  } else {
                                    shutterCamera(shutterArray[shutter - 1]);
                                  }
                                }}
                                square
                              >
                                <ArrowLeftIcon className={classes.ptdownicon} />
                              </Paper>
                            </Grid>


                          </Grid>
                        </Grid>


                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                <Divider orientation="vertical" className={classes.controlDivider} /> */}

                {/* ND */}
                {/* <Grid item>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="left"
                  >
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.slidercaption}
                      >
                        ND
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="center"

                          >

                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.topSliderButton}
                                onClick={() => {
                                  // setSpeed(100);
                                }}
                                variant="outlined"
                                square
                              >
                                <ArrowLeftIcon className={classes.ptupicon} />
                              </Paper>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" className={classes.slidertext}>------</Typography>
                            </Grid>
                            <Grid item>
                              <Paper
                                size="small"
                                className={classes.bottomSliderButton}
                                onClick={() => {
                                  // setSpeed(0);
                                }}
                                square
                              >
                                <ArrowLeftIcon className={classes.ptdownicon} />
                              </Paper>
                            </Grid>


                          </Grid>
                        </Grid>


                      </Grid>
                    </Grid>

                  </Grid>
                </Grid> */}

              </Grid>
            </Paper>
          </Paper>

          <Alert severity="warning">
            This service utilizes public network infrastructure for transmission of real-time video. Some latency may be normal.          </Alert>


        </Grid>


      </Grid>


      {/* <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <div className={classes.toolbar} />
        <Divider />

        
        {/* Lightning */}
      {/* <Paper className={classes.papercaption2}>
         <Typography variant="body2">Lightning</Typography>
       </Paper>
        <Paper className={classes.paper} variant="outlined">
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              <Grid item>
                <ButtonGroup
                  color="white"
                  aria-label="outlined primary button group"
                >
                  <Button className={classes.sliderbutton}>Day</Button>
                  <Button className={classes.sliderbutton}>Low</Button>
                </ButtonGroup>
              </Grid>
              
            </Grid>
          </Paper> */}

      {/* Autotracking */}
      {/* <Paper className={classes.papercaption2}>
         <Typography variant="body2">Autotracking</Typography>
       </Paper>
        <Paper className={classes.paper} variant="outlined">
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              
              
              <Grid item>
                <ButtonGroup
                  color="white"
                  aria-label="outlined primary button group"
                >
                  <Button className={classes.sliderbutton}>On</Button>
                  <Button className={classes.sliderbutton}>Off</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Paper>*/}
      {/* </Drawer> */}


      {/* <Grid item xs={8}>
        <Typography variant="caption" className={classes.slidercaption}>
          RTSP-Stream
        </Typography>
        <div className={classes.cameraPaper} align="center">
          <VideoPlayer {...videoJsOptions} />
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption" className={classes.slidercaption}>
          MJPEG-Stream
        </Typography>
        <div>
          <img
            src= {"http://" + props.selectedCamera.ip + "/cgi-bin/mjpeg?stream=1"}
            border="0"
            width="300"
          />
        </div>
      </Grid> */}


      {/* <Grid item xs={12} align="center">
        <div className={classes.cameraPaper}>
          <VideoPlayer {...videoJsOptions} />
        </div>
      </Grid> */}
    </div >
  );
}

export default withRouter(CameraDetail);

