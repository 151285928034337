import axios from "axios";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../../shared/constants/constants";

window.addEventListener("beforeunload", (ev) => 
{  
    ev.preventDefault();
    return ev.returnValue = 'Are you sure you want to close?';
});


export const usePrepareCameraDetail = (props) => {
  const [progress, setProgress] = useState(20);

  var imageAddr = API_BASE_URL + "cameras/testimage";
  var downloadSize = 156169; //bytes
  var quality = 1;// eslint-disable-line

  useEffect(() => {
    getAllCameras();
    // console.log(props.selectedCamera);
  }, [props.finished]);// eslint-disable-line react-hooks/exhaustive-deps
  

  const getAllCameras = async () => {

    axios
      .get(API_BASE_URL + "cameras/all", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        const cameras = response.data.filter(c => c.status === "ONLINE").sort(function (a, b) { return a.id - b.id });;
        props.setCameras(cameras);
        if (props.selectedQuality === 0) {
          initiateSpeedDetection(function () {
            if (cameras.length > 0) {
              
              if (props.selectedCamera === undefined || props.selectedCamera.length === 0) {
                props.setSelectedCamera(cameras[0]);
                startStreaming(cameras[0].id, props.selectedQuality);
                resetSettings(cameras[0].id);
              } else {
                startStreaming(props.selectedCamera.id, props.selectedQuality);
                resetSettings(props.selectedCamera.id);
              }
            }

            cameras.forEach(camera => {
              getAllPresetImages(camera);

            })
          });
        } else {
          if (cameras.length > 0) {
            // console.log(props.selectedQuality);
            if (props.selectedCamera === undefined || props.selectedCamera.length === 0) {
              props.setSelectedCamera(cameras[0]);
              startStreaming(cameras[0].id, props.selectedQuality);
              resetSettings(cameras[0].id);
            } else {
              startStreaming(props.selectedCamera.id, props.selectedQuality);
              resetSettings(props.selectedCamera.id);
            }
          }

          cameras.forEach(camera => {
            getAllPresetImages(camera);

          })
        }



      });
  };

  const showProgressMessage = (msg) => {
    if (console) {
      if (typeof msg === "string") {
        // console.log(msg);
      } else {
        for (var i = 0; i < msg.length; i++) {
          // console.log(msg[i]);
        }
      }
    }

    var oProgress = document.getElementById("progress");
    if (oProgress) {
      var actualHTML = (typeof msg === "string") ? msg : msg.join("<br />");
      oProgress.innerHTML = actualHTML;
    }
  }

  const initiateSpeedDetection = (_callback) => {
    setProgress(59);
    showProgressMessage("Loading the image, please wait...");
    measureConnectionSpeed(function () {
      _callback();
    })
  }

  const measureConnectionSpeed = (_callback) => {
    
    var startTime, endTime;
    var download = new Image();
    // download.onload = function () {
    //   endTime = (new Date()).getTime();
    //   showResults();
    startTime = (new Date()).getTime();
    var cacheBuster = "?nnn=" + startTime;
    download.src = imageAddr + cacheBuster;
    // }
    axios
      .get(imageAddr, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        endTime = (new Date()).getTime();
        var duration = (endTime - startTime) / 1000;
      var bitsLoaded = downloadSize * 8;
      var speedBps = (bitsLoaded / duration).toFixed(2);
      var speedKbps = (speedBps / 1024).toFixed(2);
      var speedMbps = (speedKbps / 1024).toFixed(2);
      // showProgressMessage([
      //   "Your connection speed is:",
      //   speedBps + " bps",
      //   speedKbps + " kbps",
      //   speedMbps + " Mbps"
      // ]);
      // console.log("Connection speed: " + speedMbps + "Mbps")
      const s = parseInt(speedMbps);

      switch (true) {
        case (s < 1.1):
          props.setSelectedQuality(2);
          quality = 2;
          _callback();
          break;

        case (s < 2.5):
          props.setSelectedQuality(2);
          quality = 2;
          _callback();
          break;

        case (s < 7.5):
          props.setSelectedQuality(2);
          quality = 2;
          _callback();
          break;

        case (s > 7.5):
          props.setSelectedQuality(1);
          quality = 1;
          _callback();
          break;


        default:
          props.setSelectedQuality(2);
          quality = 2;
          _callback();
          break;
      }

    });
  }
      
    

    // download.onerror = function (err, msg) {
    //   showProgressMessage("Invalid image, or error downloading");
    // }

    

    // function showResults() {
    //   var duration = (endTime - startTime) / 1000;
    //   var bitsLoaded = downloadSize * 8;
    //   var speedBps = (bitsLoaded / duration).toFixed(2);
    //   var speedKbps = (speedBps / 1024).toFixed(2);
    //   var speedMbps = (speedKbps / 1024).toFixed(2);
    //   // showProgressMessage([
    //   //   "Your connection speed is:",
    //   //   speedBps + " bps",
    //   //   speedKbps + " kbps",
    //   //   speedMbps + " Mbps"
    //   // ]);
    //   console.log("Connection speed: " + speedMbps + "Mbps")
    //   const s = parseInt(speedMbps);

    //   switch (true) {
    //     case (s < 1.1):
    //       props.setSelectedQuality(4);
    //       quality = 4;
    //       _callback();
    //       break;

    //     case (s < 2.5):
    //       props.setSelectedQuality(3);
    //       quality = 3;
    //       _callback();
    //       break;

    //     case (s < 7.5):
    //       props.setSelectedQuality(2);
    //       quality = 2;
    //       _callback();
    //       break;

    //     case (s > 7.5):
    //       props.setSelectedQuality(1);
    //       quality = 1;
    //       _callback();
    //       break;


    //     default:
    //       props.setSelectedQuality(2);
    //       quality = 2;
    //       _callback();
    //       break;
    //   }

    // }
  

  // const setRightStreamNumber = (speedMbps) => {
  //   console.log("Connection speed: " + speedMbps + "Mbps")
  //   const s = parseInt(speedMbps);

  //   switch (true) {
  //     case (s < 2.5):
  //       props.setSelectedQuality(2);
  //       break;

  //     case (s < 5):
  //       props.setSelectedQuality(2);
  //       break;

  //     case (s < 10):
  //       props.setSelectedQuality(2);
  //       break;

  //     case (s > 10):
  //       props.setSelectedQuality(1);
  //       break;


  //     default:
  //       props.setSelectedQuality(2);
  //       break;
  //   }

  // }

  const getAllPresetImages = async (camera) => {
    axios
      .get(API_BASE_URL + "cameras/presetimages/" + camera.id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {

        camera.presetimages = [response.data.length];

        if (response.data.forEach !== undefined) {
        response.data.forEach(function (presetId, i) {
          camera.presetimages[i] = presetId;


        });
      }

      });
  };

  const resetSettings = (id) => {
    axios
      .get(API_BASE_URL + "cameras/all", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        setTimeout(() => {
          setProgress(95);
        }, 1500);
      });
  };

  const startStreaming = (id, quality) => {

    if (quality === undefined || (quality === 0 && id !== 7)) {
      props.setSelectedQuality(2);
      quality = 2;
    } else if (id === 7) {
      props.setSelectedQuality(1);
      quality = 1;
    }
    const url = API_BASE_URL + "cameras/start/" + id + "/" + quality
    // console.log(url);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        // console.log(response.data);
        if ((response.data = true)) {
          setProgress(79);
          setTimeout(() => {
            props.setFinished(true);
            // console.log("finished set to true");
          }, 5000);
        }
      })
      .catch(function (error) {

      //  console.log(error);

    });
  };

  return { progress };
};
