import { makeStyles } from "@mui/styles";
const drawerWidth = 480;
const styleCameraDetail = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  tabs: {
    marginTop: 10,
    backgroundColor: "#161618",
  },

  tabPaper: {
    marginTop: 10,
    backgroundColor: "#161618",
  },
  commandPaper: {
    padding: 10,
    backgroundColor: "#161618",
  },

  tabtext: {
    textColor: "white",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  slidertext: {
    color: "#9c9c9c",
  },
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor: "#161618",
    backgroundColor: "black",
  },
  selectedPaper: {
    height: 5,
    backgroundColor: theme.palette.primary.main,
  },
  unSelectedPaper: {
    height: 5,
    backgroundColor: "#464646",
  },
  singleCamera: {
    borderRadius: 5,
    padding: 10,
    margin: 20,
    '&:hover': {
      background: "#707070",
    },
    color: "white",
  },
  singleCameraSelected: {
    color: "#161618",
    borderRadius: 5,
    padding: 10,
    margin: 20,
    '&:hover': {
      background: "#707070",
    },
    background: "white",
  },

  singleQuality: {
    borderRadius: 5,
    padding: 10,
    margin: 20,
    '&:hover': {
      background: "#707070",
    },
    color: "white",
  },
  singleQualitySelected: {
    color: "#161618",
    borderRadius: 5,
    padding: 10,
    margin: 20,
    '&:hover': {
      background: "#707070",
    },
    background: "white",
  },

  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      // minHeight: 200,
    },
    // minWidth: 100,
    backgroundColor: "#161618",
    // borderRadius: 0.5,
    // margin: 2,
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    color: "white",
  },

  papercaption1: {

    // backgroundColor: "#464646",
    backgroundColor: "#161618",
    textAlign: "center",
    // color: "#9c9c9c",
    color: "white",
    paddingTop: 10,
    paddingBottom: 4,
    // padding: 4,

  },
  papercaption2: {

    backgroundColor: "#161618",
    textAlign: "center",
    color: "white",
    paddingTop: 10,
    paddingBottom: 4,
  },
  controlRow: {
    height: "100%"
  },
  camselect: {
    color: "#b7b7b7",
    backgroundColor: "#444445",
  },
  cameraPaper: {
    maxWidth: 1800,
    // padding: 4,
    backgroundColor: "black",
  },
  prepareCameraPaper: {
    maxWidth: 1800,
    aspectRatio: 16/9,
    padding: 4,
    backgroundColor: "black",
  },
  videopaper: {
    maxWidth: 1800,
    aspectRatio: 16/9,
    padding: 4,
    backgroundColor: "black",
  },
  buttongroup: {
    backgroundColor: "#444445",
  },
  lightbutton: {
    color: "#b7b7b7",
  },
  ptbutton: {
    border: 0,
    textTransform: "none",
    height: 35,
    width: 45,
    backgroundColor: "#444445",
    margin: 1,
    '&:hover': {
      background: "#707070",
    },
  },
  ptmiddlebutton: {
    border: 0,
    textTransform: "none",
    height: 35,
    width: 45,
    backgroundColor: "#161618",
    margin: 1,
  },
  presetbutton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 71,
    padding: 1,
    borderWidth: 0,
  },

  presetbuttonselected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    padding: 1,
    backgroundColor: theme.palette.secondary.main,
    borderWidth: 0,
  },
  presetimage: {
    borderWidth: 0,
  },
  sliderbutton: {
    textTransform: "none",
    backgroundColor: "#444445",
    margin: 2,
    color: "#b7b7b7",
    height: 25,
    width: 40,
    textAlign: "center",
    borderColor: "#000",
    borderRadius: 2,
  },

  sliderbuttonselected: {
    textTransform: "none",
    backgroundColor: theme.palette.secondary.main,
    margin: 2,
    color: "#b7b7b7",
    height: 25,
    width: 40,
    textAlign: "center",
    borderRadius: 2,
  },
  setpresetbutton: {

    margin: 10,
    backgroundColor: "#444445",
    color: "#b7b7b7",
    height: 25,
    width: 40,
    textAlign: "center",
    borderColor: "#000",
    borderRadius: 2,

  },
  setpresetbuttonwider: {

    margin: 10,
    backgroundColor: "#444445",
    color: "#b7b7b7",
    height: 25,
    width: 80,
    textAlign: "center",
    borderColor: "#000",
    borderRadius: 2,

  },
  topSliderButton: {
    marginBottom: 20,
    textTransform: "none",
    backgroundColor: "#444445",
    color: "#b7b7b7",
    height: 25,
    width: 40,
    textAlign: "center",
    borderColor: "#000",
    borderRadius: 2,
  },
  topSliderButtonAuto: {
    marginBottom: 30,
    textTransform: "none",
    backgroundColor: "#444445",
    color: "#b7b7b7",
    height: 25,
    width: 40,
    textAlign: "center",
    borderColor: "#000",
    borderRadius: 2,
  },
  bottomSliderButton: {
    marginTop: 20,
    textTransform: "none",
    backgroundColor: "#444445",
    color: "#b7b7b7",
    height: 25,
    width: 40,
    textAlign: "center",
    borderColor: "#000",
    borderRadius: 2,
  },
  bottomSliderButtonAuto: {
    marginBottom: 5,
    textTransform: "none",
    backgroundColor: "#444445",
    color: "#b7b7b7",
    height: 25,
    width: 40,
    textAlign: "center",
    borderColor: "#000",
    borderRadius: 2,
  },
  ptuplefticon: {
    transform: "rotate(45deg)",
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  ptupicon: {
    transform: "rotate(90deg)",
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  ptuprighticon: {
    transform: "rotate(135deg)",
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  ptrighticon: {
    transform: "rotate(180deg)",
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  ptdownrighticon: {
    transform: "rotate(225deg)",
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  ptdownicon: {
    transform: "rotate(270deg)",
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  ptdownlefticon: {
    transform: "rotate(315deg)",
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  ptlefticon: {
    color: "#b7b7b7",
    height: 35,
    width: 35,
  },
  slider: {
    color: theme.palette.secondary.main,
    minHeight: 110,
    markLabel: {
      color: "white",
      
    },
  },

  sliderhorizontal: {
    color: theme.palette.secondary.main,
    markLabel: {
      color: "white",
      
    },
  },
  slidercaption: {
    color: "grey",
  },
  controlDivider: {
    backgroundColor: "#434345",
    height: 130
  },

  

}));

export { styleCameraDetail };
