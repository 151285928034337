
import { makeStyles } from "@mui/styles";

const styleEmailConfirm = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.background,
      },
      paper2: {
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
      },
      avatar: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
      },
      form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(3),
      },
      submit: {
          margin: theme.spacing(3, 0, 2),
      },
      logotext: {
        marginTop: theme.spacing(1),
        fontSize: 22,
        fontWeight: 'medium',
      },
      titletext: {
        marginTop: theme.spacing(1),
        fontSize: 28,
        fontWeight: 'regular',
      },
}));

export {styleEmailConfirm};