import { Typography } from "@mui/material";
import React from "react";
import AWHE40 from "../../shared/deviceImages/AW-HE40W.png";
import AWHN38 from "../../shared/deviceImages/AW-HN38W.png";
import AWHR140 from "../../shared/deviceImages/AW-HR140.png";
import AWUE100 from "../../shared/deviceImages/AW-UE100W.png";
import AWUE150 from "../../shared/deviceImages/AW-UE150W.png";
import AWUE40 from "../../shared/deviceImages/AW-UE40W.png";
import AWUE4 from "../../shared/deviceImages/AW-UE4W.png";
import AWUE50 from "../../shared/deviceImages/AW-UE50W.png";
import AWUN70 from "../../shared/deviceImages/AW-UE70W.png";
import AWUE80 from "../../shared/deviceImages/AW-UE80W.png";
import AWUE160 from "../../shared/deviceImages/AW-UE160W.png";

import { styleSingleCameraSmall } from "./styleSingleCameraSmall";


function SingleCameraSmall(props) {
  const classes = styleSingleCameraSmall();

  return (
    <div className={classes.croot}>

      {/* <div align={props.width !== "xs" ? "center" : "left"}> */}
      {/* <div align="center">
        {props.camera.information[4]&&
        
          {
            "AW-UE4": <img src={AWUE4} className={classes.productimage} />,
            "AW-HN38": <img src={AWHN38} className={classes.productimage} />,
            "AW-HE40": <img src={AWHE40} className={classes.productimage} />,
            "AW-UN70": <img src={AWUN70} className={classes.productimage} />,
            "AW-UE100": <img src={AWUE100} className={classes.productimage} />,
            "AW-UE150": <img src={AWUE150} className={classes.productimage} />,
            "AW-HR140": <img src={AWHR140} className={classes.productimage} />,
          }[props.camera.information[4].split(":")[1]]
        }
        
        
      </div>
      <Typography gutterBottom variant="body2" align="center">
        {props.camera.information[4].split(":")[1]}
      </Typography> */}
      
      {props.camera.information[4] &&
        <div>
          <div align="center">
            {
              {
                "AW-UE4": <img src={AWUE4} className={classes.productimage} alt="AW-UE4"/>,
                "AW-HN38": <img src={AWHN38} className={classes.productimage} alt="AW-HN38"/>,
                "AW-HE40": <img src={AWHE40} className={classes.productimage} alt="AW-HE40"/>,
                "AW-UN70": <img src={AWUN70} className={classes.productimage} alt="AW-UN70"/>,
                "AW-UE100": <img src={AWUE100} className={classes.productimage} alt="AW-UE100"/>,
                "AW-UE150": <img src={AWUE150} className={classes.productimage} alt="AW-UE150"/>,
                "AW-HR140": <img src={AWHR140} className={classes.productimage} alt="AW-HR140"/>,
                "AW-UE40": <img src={AWUE40} className={classes.productimage} alt="AW-UE40"/>,
                "AW-UE50": <img src={AWUE50} className={classes.productimage} alt="AW-UE50"/>,
                "AW-UE80": <img src={AWUE80} className={classes.productimage} alt="AW-UE80"/>,
                "AW-UE160": <img src={AWUE160} className={classes.productimage} alt="AW-UE160" />,


              }[props.camera.information[4].split(":")[1]]
            }
          </div>

          <Typography gutterBottom variant="body2" align="center">
            {props.camera.information[4].split(":")[1]}
          </Typography>
        </div>
      }
    </div>
  );
}

export default SingleCameraSmall;
