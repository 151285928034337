import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { withRouter } from "react-router-dom";
import { styleTourSentence } from "./styleTourSentence";
import { useTourSentence } from "./useTourSentence";
import AddIcon from "@mui/icons-material/Add";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React, { useState } from 'react';
import NewBooking from "../Booking/NewBooking/NewBooking";
import { useServerApi } from "../../shared/controller/useServerApi";
import Countdown from 'react-countdown';
import { red } from "@mui/material/colors";

function TourSentence(props) {
    const classes = styleTourSentence();
    const { handleNewBookingClose, handleNewBookingOpen, openNewBooking } = useTourSentence(props);
    const { redirectToWaitingRoom, redirectToTour } = useServerApi(props);
    const [redirectAutomatically, setRedirectAutomatically] = useState(false);

    const handleRedirectToggle = () => {
        setRedirectAutomatically((redirectAutomatically) => !redirectAutomatically);
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed && redirectAutomatically) {
            // Render a completed state
            
            redirectToTour();
            return <Typography>Tour is being started...</Typography>;
        } else {
            // Render a countdown
            if (hours != 0) {
                return <span>{hours}h {minutes}min {seconds}s</span>;
            } else {
                return <span>{minutes}min {seconds}s</span>;
            }

        }
    };

    return (
        <Grid container justifyContent="space-between" alignItems="center" className={classes.root}>
            <Grid item lg={8}>
                {/* If no upcoming tour */}
                {!props.earliestBookingDate &&
                    <Typography variant="h6" className={classes.pagesubtitle}>You have no upcoming tours.</Typography>
                }
                {/* If current tour */}
                {props.earliestBookingDate && props.bookingIsCurrent &&
                    <Typography variant="h6" className={classes.pagesubtitle}>You have an ongoing tour right now.</Typography>
                }
                {/* If upcoming tour */}
                {props.earliestBookingDate && !props.bookingIsCurrent &&

                    <Typography variant="h6" className={classes.pagesubtitle}>
                        {/* If tour today */}
                        {props.daysBetween === 0 &&
                            "You have an upcoming tour today at " + props.earliestBookingDate.getHours().toString().padStart(2, "0") + ":" + props.earliestBookingDate.getMinutes().toString().padStart(2, "0") + "."
                        }
                        {/* If tour tomorrow */}
                        {props.daysBetween === 1 &&
                            "You have an upcoming tour tommorrow at " + props.earliestBookingDate.getHours().toString().padStart(2, "0") + ":" + props.earliestBookingDate.getMinutes().toString().padStart(2, "0") + "."
                        }
                        {/* If tour further in future */}
                        {props.daysBetween > 1 &&
                            "You have an upcoming tour in " + props.daysBetween + " days."
                        }
                    </Typography>
                }
            </Grid>
            <Grid item alignContent="right">
                {props.earliestBookingDate && props.bookingIsCurrent &&
                    <Button
                        variant="contained"
                        className={classes.startbutton}
                        startIcon={<PlayArrowIcon />}
                        onClick={redirectToWaitingRoom}
                    >Start tour</Button>
                }
                {props.earliestBookingDate && !props.bookingIsCurrent &&

                    <Button
                        variant="contained"
                        className={redirectAutomatically ? classes.waitbutton : classes.startbutton}
                        startIcon={redirectAutomatically ? <PauseIcon /> : < PlayArrowIcon />}
                        onClick={() => {handleRedirectToggle()}}
                    >

                        {redirectAutomatically ?
                            <Typography>Exit waiting room</Typography>
                            :
                            <Typography>Waiting room</Typography>
                        }

                    </Button>
                }

                
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={handleNewBookingOpen}
                >
                    new booking
                </Button>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={openNewBooking}
                    onClose={handleNewBookingClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">Make new booking</DialogTitle>
                    <DialogContent>
                        <NewBooking handleClose={handleNewBookingClose} setReload={props.setReload} />
                    </DialogContent>
                </Dialog>





            </Grid>
            {redirectAutomatically &&
                <Paper className={classes.redirectPaper}>
                    <Typography variant="h6" className={classes.redirectText}>
                    You will be redirected in <Countdown date={props.earliestBookingDate} renderer={renderer} />
                    </Typography>
                </Paper>
                }
            {/* <Divider className={classes.divider} /> */}
        </Grid>
    );

}

export default withRouter(TourSentence);
