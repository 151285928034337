
import { makeStyles } from "@mui/styles";

const styleAdmin = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    height: "max-content",
    minWidth: 650,
    marginBottom: 25
  },
  tablecontainer: {
    // height: 350
  },
  tourpreviewbutton: {
    color: "white",
    background: theme.palette.secondary.main,
    marginBottom: 10,
  },
  section: {
    marginBottom: 10,
  }
}));

export { styleAdmin };