import { useState } from "react";
import { useServerApi } from "../../../../shared/controller/useServerApi";

export const useUpdatePassword = (props) => {
    const { updatePassword } = useServerApi(props);
    const [updatePasswordResponse, setUpdatePasswordResponse] = useState();

    const [state, setState] = useState({
        password: "",
        passwordConfirm: "",
        successMessage: null
    })

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        updatePassword(state.password, state.passwordConfirm, props.match.params.token, setUpdatePasswordResponse);
    }

    return { state, setState, handleChange, handleSubmit, updatePasswordResponse };
}