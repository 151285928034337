import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from '@mui/material/FormControl';
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import Link from "@mui/material/Link";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { withRouter } from "react-router-dom";
import logo from "../../../shared/logo.svg";
import BottomLinks from "../BottomLinks/BottomLinks";
import { styleSigning } from "../styleSigning";
import { useRegister } from "./useRegister";

function Register(props) {
  const classes = styleSigning();
  const { state,
    handleChange,
    handleSubmit,
    handleIndustryChange, industry, handleBranchChange, branch, handleCountryChange, country,
    registrationResponse, handleCheckboxChange, agreedToAGB, handleGDPRCheckboxChange, agreedToGDPR, countryList } = useRegister(props);

  const onVerifyCaptcha = (token) => {
    // console.log("Verified: " + token)
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Box component="span" m={3}>
            <div className={classes.paper2}>
              <img src={logo} className={classes.logoimage} alt="Panasonic Connect Europe Logo" />
              <div className={classes.logotext}>Virtual Demo Room</div>
            </div>
          </Box>
          <Typography className={classes.titletext}>
            Registration
          </Typography>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  autoComplete="firstname"
                  value={state.firstname}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="lastname"
                  value={state.lastname}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Business Email"
                  name="email"
                  autoComplete="email"
                  value={state.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile Phone"
                  name="mobile"
                  autoComplete="mobile"
                  value={state.mobile}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="company"
                  label="Company Name"
                  name="company"
                  autoComplete="company"
                  value={state.company}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Country *</InputLabel>
                  <Select
                    required
                    value={country}
                    onChange={handleCountryChange}
                    label="Country"
                  >
                    {countryList.map((country, index) => {
                      return (
                        <MenuItem value={country} key={index}>{country}</MenuItem>
                      )

                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Industry</InputLabel>
                  <Select
                    value={industry}
                    onChange={handleIndustryChange}
                    label="Industry"
                  >
                    <MenuItem value={"Dealer"}>Dealer</MenuItem>
                    <MenuItem value={"Distributor"}>Distributor</MenuItem>
                    <MenuItem value={"Manufacturer"}>Manufacturer</MenuItem>
                    <MenuItem value={"System Integrator"}>System integrator</MenuItem>
                    <MenuItem value={"Service Provider"}>Service Provider</MenuItem>
                    <MenuItem value={"End Customer"}>End Customer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Branch</InputLabel>
                  <Select
                    value={branch}
                    onChange={handleBranchChange}
                    label="Branch"
                  >
                    <MenuItem value={"Dealer"}>Dealer</MenuItem>
                    <MenuItem value={"TV Broadcasting"}>TV Broadcasting</MenuItem>
                    <MenuItem value={"Higher Education"}>Higher Education</MenuItem>
                    <MenuItem value={"Event & Staging"}>Event & Staging</MenuItem>
                    <MenuItem value={"Medical"}>Medical</MenuItem>
                    <MenuItem value={"Stadium"}>Stadium</MenuItem>
                    <MenuItem value={"Sports"}>Sports</MenuItem>
                    <MenuItem value={"Film Production"}>Film Production</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={8}>

                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={state.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={4} >
                <Grid container direction="row">
                  <Grid item xs={2} sm={2}>
                    <CheckCircleIcon fontSize="small" color={state.password.length > 12 ? "primary" : "disabled"} />
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography variant="caption">
                      Password should be at least 12 characters long
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={8} sm={8}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password_confirm"
                  label="Confirm password"
                  type="password"
                  id="passwordConfirm"
                  autoComplete="current-password"
                  value={state.passwordConfirm}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={4} sm={4} >
                <Grid container direction="row">
                  <Grid item xs={2} sm={2}>
                    <CheckCircleIcon fontSize="small" color={state.password === state.passwordConfirm && state.password.length > 0 ? "primary" : "disabled"} />
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography variant="caption">
                      Re-enter your password
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <Typography variant="caption">
                  Please use a password which is at least 12 characters long.
                </Typography>
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <Typography variant="caption">* = mandatory</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToAGB}
                      onChange={handleCheckboxChange}
                      name="checkedB"
                      color="primary"

                    />
                  }
                  label={
                    <Typography variant="caption">
                      I agree to the <a href="https://eu.connect.panasonic.com/gb/en/terms-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and have read and understood the <a href="https://eu.connect.panasonic.com/gb/en/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                    </Typography>
                  }


                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToGDPR}
                      onChange={handleGDPRCheckboxChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="caption">
                      I acknowledge the <a href="https://business.panasonic.co.uk/professional-camera/privacy-policy" target="_blank" rel="noopener noreferrer">information on data protection of Panasonic Connect Europe GmbH</a>.</Typography>
                  }


                /> */}
              </Grid>

            </Grid>
            {/* <input type="text" id="name" className="input"/>
                <reCAPTCHA /> */}
            {registrationResponse &&
              <Alert severity="error" className={classes.form}>
                {registrationResponse}
              </Alert>
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            // disabled
            >
              Registration
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2" className={classes.bottomnav}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>

        </div>
      </Container>
      <BottomLinks />
    </Box>
  );
}

export default withRouter(Register);
