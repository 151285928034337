import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

const drawerWidth = 240;
const styleDashboard = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: 600,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  a: {
    color: "black",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  emailPaper: {
    margin: 10,
    padding: 10,
    backgroundColor: theme.palette.text.secondary,
    color: "white",
  },
  logouttext: {

    // backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  settingstext: {

    // backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#fff",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarButton: {
    marginLeft: 100,
    color: 'white',
  },
  menuButton: {
    // marginRight: 36,
    color: 'white',
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    maxWidth: 175,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  lightcontent: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  darkcontent: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#000000",
    color: grey[50],
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  accountText: {
    textTransform: "none",
  },

  dialog: {
    position: 'absolute',
    bottom: 2,
    right: 2
  },
}));

export { styleDashboard };
