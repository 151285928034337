import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useDateForm } from "./useDateForm";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function DateForm(props) {
  const { handleDateChange } = useDateForm(props);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="h5" align="center" gutterBottom>
            Which day works best for you?
          </Typography>
        </Grid>
        <Grid item>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              autoOk
              orientation="landscape"
              variant="static"
              // openTo="date"
              value={props.selectedDate}
              onChange={handleDateChange}
              disablePast
              disableToolbar
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {/* </MuiPickersUtilsProvider> */}
        </Grid>
        {/* <Grid item>
                <Typography variant="subtitle2" align="right" gutterBottom className={classes.pickertext}>
                    When done, press next to choose a timeslot for that day.
                </Typography>
            </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
