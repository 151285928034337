import { makeStyles } from "@mui/styles";
import { green, red, yellow } from "@mui/material/colors";

const styleSingleCamera = makeStyles((theme) => ({
  croot: {
    padding: 10,
    height: 300,
    [theme.breakpoints.down('md')]: {
      height: 275,
    },
    
  },
  productimage: {
    height: 110,
    [theme.breakpoints.down('md')]: {
      height: 80,
    },
  },
  productdescription: {
    height: 50,

    [theme.breakpoints.down('md')]: {
      height: 45,
      // fontSize: 15,
    },
    marginBottom: 5,
  },
  onlinechip: {
    color: green[500],
  },
  standbychip: {
    color: yellow[800],
  },
  offlinechip: {
    color: red[800],
  },
}));

export { styleSingleCamera };
