import { useEffect, useState } from "react";
import { useServerApi } from "../../shared/controller/useServerApi";

export const useStart = (props) => {
  const [earliestBookingDate, setEarliestBookingDate] = useState();
  const [bookingIsCurrent, setBookingIsCurrent] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [daysBetween, setDaysBetween] = useState(-1);
  const { upcomingBooking, redirectToBooking, allCameras } = useServerApi(props);
  const [reload, setReload] = useState(false);


  useEffect(() => {
    upcomingBooking(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween);
    allCameras(setCameras, setReload);

    setReload(false);
    let iv = setInterval(() => {
      upcomingBooking(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween);
      allCameras(setCameras, setReload);
    }, 20 * 1000)
    return () => {
      clearInterval(iv);
    };
  }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

  const [openNewBooking, setOpenNewBooking] = useState(false);

  const handleNewBookingOpen = () => {
    setOpenNewBooking(true);
  };

  const handleNewBookingClose = () => {
    setReload(true);
    setOpenNewBooking(false);
  };

  return {
    earliestBookingDate, redirectToBooking, bookingIsCurrent, cameras, daysBetween,
    openNewBooking, setOpenNewBooking, handleNewBookingOpen, handleNewBookingClose, setReload
  };
};
