import { useEffect, useState } from "react";
import { useServerApi } from "../../../shared/controller/useServerApi";

export const useBookingOverview = (props) => {
    const { upcomingBooking, allUpcomingBookings, redirectToBooking, cancelBooking } = useServerApi(props);

    const [earliestBookingDate, setEarliestBookingDate] = useState();
    const [bookingIsCurrent, setBookingIsCurrent] = useState(false);
    const [upcomingBookings, setUpcomingBookings] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [daysBetween, setDaysBetween] = useState(-1);
    const [reload, setReload] = useState(false);
    const [openNewBooking, setOpenNewBooking] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [currentBookingId, setCurrentBookingId] = useState(0);

    const handleNewBookingOpen = () => {
        setOpenNewBooking(true);
    };

    const handleNewBookingClose = () => {
        setReload(!reload);
        setOpenNewBooking(false);
    };

    const handleOpenCancelDialog = (id) => {
        setCurrentBookingId(id);
        setOpenCancelDialog(true);
    };

    const handleCloseCancelDialog = () => {
        setOpenCancelDialog(false);
        setReload(!reload);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCancelClick = () => {
        cancelBooking(currentBookingId); 
        handleCloseCancelDialog();
        setReload(!reload);
    }
    

    useEffect(() => {
        upcomingBooking(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween);
        allUpcomingBookings(new Date(), setUpcomingBookings);
        setReload(false);
    }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

    return { 
        page, setPage, rowsPerPage, setRowsPerPage, handleChangePage, handleChangeRowsPerPage, 
        upcomingBookings, earliestBookingDate, bookingIsCurrent, redirectToBooking, handleCancelClick,
        openNewBooking, setOpenNewBooking, handleNewBookingOpen, handleNewBookingClose, setReload, 
        daysBetween, openCancelDialog, handleCloseCancelDialog, handleOpenCancelDialog};
}