import { Button, Chip, Dialog, DialogActions, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ClearIcon from '@mui/icons-material/Clear';
// import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { withRouter } from "react-router-dom";
import TourSentence from "../../TourSentence/TourSentence";
import { styleBookingOverview } from "./styleBookingOverview";
import { useBookingOverview } from "./useBookingOverview";
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function BookingOverview(props) {
    const classes = styleBookingOverview();
    const {
        upcomingBookings, earliestBookingDate, bookingIsCurrent, daysBetween,
        setReload, handleCancelClick, openCancelDialog, handleCloseCancelDialog,
        handleOpenCancelDialog } = useBookingOverview(props);

    return (


        <div>
            {/* Section title */}

            {/* <Divider className={classes.divider} /> */}







            {/* All upcoming bookings table */}
            {upcomingBookings.length > 0 &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6" className={classes.pagetitle}>Bookings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* <Divider className={classes.divider} /> */}
                        {/* <Typography variant="h5" className={classes.pagetitle}>
            All bookings
        </Typography> */}
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Status
                                            </TableCell>
                                            <TableCell>
                                                Start
                                            </TableCell>
                                            <TableCell>
                                                Date
                                            </TableCell>
                                            <TableCell>
                                                Duration
                                            </TableCell>
                                            <TableCell>
                                                Cancel tour
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {upcomingBookings.map((booking) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={booking.date}>
                                                    <TableCell >
                                                        {booking[3] === true ?
                                                            <Chip variant="outlined" size="small" label="Cancelled" className={`${classes.cancelchip} ${classes.statuschip}`} />
                                                            :
                                                            <Chip variant="outlined" size="small" label="Confirmed" className={`${classes.confirmedchip} ${classes.statuschip}`} />
                                                        }


                                                    </TableCell>
                                                    <TableCell >
                                                        {moment(booking[1]).format("HH:mm")}
                                                    </TableCell>
                                                    <TableCell >
                                                        {moment(booking[1]).format("MMMM Do YYYY")}
                                                    </TableCell>

                                                    <TableCell >
                                                        {30 * booking[2].length} minutes
                                                    </TableCell>
                                                    <TableCell >
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            className={classes.cancelbutton}
                                                            onClick={() => handleOpenCancelDialog(booking[0])}
                                                            disabled={booking[3] === true}
                                                            color="secondary"
                                                        >
                                                            <ClearIcon />
                                                        </Button>

                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                <Dialog
                                    open={openCancelDialog}
                                    onClose={handleCloseCancelDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to cancel this tour?"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            If you cancel this tour, your reserved slots can be booked by others again.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseCancelDialog} color="primary">
                                            No
                                        </Button>
                                        <Button onClick={() => handleCancelClick()} color="primary" autoFocus>
                                            Yes
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableContainer>
                            {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={upcomingBookings.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                        </Paper>

                    </AccordionDetails>
                </Accordion>
            }

        </div>



    );
}

export default withRouter(BookingOverview);
