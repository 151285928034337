
import { makeStyles } from "@mui/styles";

const styleNewBooking = makeStyles((theme) => ({
    
      // layout: {
      //   width: 'auto',
      //   marginLeft: theme.spacing(2),
      //   marginRight: theme.spacing(2),
      //   [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      //     width: 600,
      //     marginLeft: 'auto',
      //     marginRight: 'auto',
      //   },
      // },
  root: {
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding:40,
    },
  },
  paper: {
      height: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(6),
      },
  },
  stepper: {
    padding: theme.spacing(1),
    backgroundColor: "transparent"
  },
  buttons: {
    marginTop: 60,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export { styleNewBooking};