import { Card, CardActionArea} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { SLOTAMOUNT } from "../../../../shared/constants/constants";
import { styleSlotForm } from "./styleSlotForm";
import { useSlotForm } from "./useSlotForm";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";

function SlotForm(props) {
  const classes = styleSlotForm();
  const {
    takenSlots,
    handleSlotClick,
    getSlotStartTimeString,
    getSlotEndTimeString,
  } = useSlotForm(props);

  const checkIfSlotIsInPast = (slot) => {
    const now = new Date();
    return now > slot;
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        className={classes.pickertext}
      >
        {props.selectedSlots.length !== SLOTAMOUNT &&
          `You can select up to ${SLOTAMOUNT - props.selectedSlots.length} ${props.selectedSlots.length > 0 ? "more" : ""
          } adjacent timeslots.`}
        {props.selectedSlots.length === SLOTAMOUNT &&
          "You have selected 4 slots."}
      </Typography>

      <Typography
        variant="body1"
        align="left"
        gutterBottom
        className={classes.selectionlabel}
      >
        {props.selectedDate.toDateString()}
        {props.selectedSlots.length > 0 &&
          `, from ${getSlotStartTimeString(
            props.selectedSlots.sort((a, b) => a.getTime() - b.getTime())[0]
          )} to ${getSlotEndTimeString(
            props.selectedSlots.sort((a, b) => a.getTime() - b.getTime())[
            props.selectedSlots.length - 1
            ]
          )}.`}
      </Typography>

      <Grid container className={isMobile ? classes.gridListmobile : classes.gridList} cols={1}>
        {props.slots.map((slot, i) => (
          !checkIfSlotIsInPast(slot) &&
          <div style={{ height: 30 }}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              direction={isMobile ? "row" : "column"}
              spacing={5}
            >
              <Grid item xs={6}>
                <Card
                  className={isMobile ? classes.slotbuttonmobile : classes.slotbutton}
                  onClick={(event) => {
                    handleSlotClick(slot);
                  }}
                  onDragStart={(event) => {
                    handleSlotClick(slot);
                  }}
                >
                  {takenSlots.includes(i - (48 - props.slots.length)) && (

                    //Slot is not selected
                    <CardActionArea
                      className={classes.slotactionareataken}
                      disabled
                    />
                  )}
                  {!props.selectedSlots.includes(slot) && (
                    //Slot is not selected
                    <CardActionArea className={classes.slotactionarea} />
                  )}
                  {props.selectedSlots.includes(slot) && (
                    //Slot is selected
                    <CardActionArea
                      className={classes.slotactionareaselected}
                    />
                  )}
                </Card>

              </Grid>
              <Grid item xs={6}>
                {isMobile ? (
                  <Typography
                    variant="overline"
                    className={classes.slotlabelmobile}
                    align="center"
                  >
                    {/* {getSlotStartTimeString(slot)} -{" "}
                    {getSlotEndTimeString(slot)}
                     */}
                     {getSlotStartTimeString(slot)} - {getSlotEndTimeString(slot)}
                    
                  </Typography>
                ) : (
                  <div>
                    <Typography
                      variant="overline"
                      className={classes.slotlabel}
                    >
                      {getSlotStartTimeString(slot)}
                    </Typography>
                    <Typography
                      variant="overline"
                      className={classes.slotminus}
                      display="block"
                    >
                      –
                    </Typography>
                    <Typography
                      variant="overline"
                      className={classes.slotlabel}
                    >
                      {getSlotEndTimeString(slot)}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>

        ))}
      </Grid>
    </React.Fragment>
  );
}

export default SlotForm;
