import axios from "axios";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../shared/constants/constants";
import { useServerApi } from "../../shared/controller/useServerApi";
import moment from "moment";

export const useAdmin = (props) => {
  const [newUsers, setNewUsers] = useState([]);
  const [verifiedUsers, setVerifiedUsers] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const { redirectToTour, createDateFromServer } = useServerApi(props);

  useEffect(() => {
    getAllUsers();
    getAllBookings();
  }, []);

  const getAllUsers = () => {
    axios
      .get(API_BASE_URL + "admin/users", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        const users = response.data;
        setNewUsers(users.filter((user) => user.appUserRole === "NEW"));
        setVerifiedUsers(users.filter((user) => user.appUserRole === "USER"));
      });
  };

  const getAllBookings = () => {
    axios
      .get(API_BASE_URL + "admin/bookings", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
      })
      .then(function (response) {
        const bookings = response.data;
        var imported = [];
        console.log(bookings)
        bookings.forEach(function (booking) {
          if (booking.date !== undefined) {
            console.log(booking)
            booking.date = createDateFromServer(booking.date);
            imported.push([booking.id, booking.date, booking.slots, booking.cancelled, booking.email]);
          }
        });

        // First, get the current date using Moment.js
        var currentDate = moment();

        // Then, get the date 7 days ago
        var sevenDaysAgo = moment().subtract(7, 'days');

        // Filter out any dates that are older than 7 days
        var filtered = imported.filter(function (booking) {
          return moment(booking[1]).isAfter(sevenDaysAgo);
        });

        filtered.sort(function(a, b) {
          return moment(a[1]).valueOf() - moment(b[1]).valueOf()
        })

        setAllBookings(filtered);
      });
  };

  const getDate = (d) => {
    if (d != null) {
      const newDate = new Date();
      newDate.setFullYear(d[0]);
      newDate.setMonth(d[1] - 1);
      newDate.setDate(d[2]);
      newDate.setHours(d[3] + 1, d[4], d[5]);
      return newDate.toLocaleString();
    }
  };

  const verify = (id) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    const params = {
      id: id,
    };
    axios
      .post(API_BASE_URL + "admin/verify", null, {
        params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        getAllUsers();
      });
  };

  const deleteUser = (id) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    const params = {
      id: id,
    };
    axios
      .post(API_BASE_URL + "admin/delete", null, {
        params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        // // console.log(response.data);
        getAllUsers();
      });
  };

  const resendVerificationEmail = (id) => {
    const params = {
      id: id,
    };
    axios
      .post(API_BASE_URL + "admin/resendVerificationEmail", null, {
        params,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        return response.data;
      });
  }


  return { newUsers, verifiedUsers, allBookings, getDate, verify, deleteUser, redirectToTour, resendVerificationEmail };
};
