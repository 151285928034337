import axios from "axios";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../shared/constants/constants";
import { useServerApi } from "../../shared/controller/useServerApi";

export const useTour = (props) => {
  const [finished, setFinished] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(0);
  
  // const [earliestBookingDate, setEarliestBookingDate] = useState(false);
  // const [bookingIsCurrent, setBookingIsCurrent] = useState(false);
  // const [daysBetween, setDaysBetween] = useState(false);
  
  // const [user, setUser] = useState();
  const { logout, redirectToWaitingRoom } = useServerApi(props);
  const [canStartTour, setCanStartTour] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect( () => {
    const getUserDetails = () => {
      axios
        .get(API_BASE_URL + "user", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_NAME)}`,
          },
        })
        .then((response) => {
          if (response.data !== "") {
            if (response.data.appUserRole === "ADMIN") {
              setCanStartTour(true);
              setIsAdmin(true);
            } else {
              // upcomingBooking(setEarliestBookingDate, setBookingIsCurrent, setDaysBetween);
            }
            
            // console.log(bookingIsCurrent);
            // console.log(response.data.appUserRole);
            
          } else {
            // token expired
            logout();
          }
    
        })
        .catch((error) => {
          // console.log(error);
          // logout();
        });
    };
    getUserDetails();
    // if (bookingIsCurrent) {
      setCanStartTour(true);
    // }
}, [logout]);



  return {
    finished,
    setFinished,
    cameras,
    setCameras,
    selectedCamera,
    setSelectedCamera,
    selectedQuality,
    setSelectedQuality,
    // bookingIsCurrent,
    // user,
    redirectToWaitingRoom,
    canStartTour,
    isAdmin
  };
};
