import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grow from '@mui/material/Grow';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from '@mui/icons-material/Person';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import clsx from "clsx";
import React from "react";
import { withRouter } from "react-router-dom";
import logo from "../../shared/p_connect_logo.svg";
import Content from "./Content";
import { styleDashboard } from "./styleDashboard";
import { useDashboard } from "./useDashboard";
import BottomLinks from "../Signing/BottomLinks/BottomLinks";

function Dashboard(props) {
  const classes = styleDashboard();
  const {
    open,
    handleDrawerOpen,
    handleDrawerClose,
    redirectToAdmin,
    redirectToBooking,
    redirectToStart,
    handleLogout,
    user,
    redirectToTour,
    redirectToWaitingRoom,
    redirectToSettings
  } = useDashboard(props);

  const [openSettings, setOpenSettings] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpenSettings((prevOpen) => !prevOpen);
  };

  const handleCloseSettings = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenSettings(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSettings(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openSettings);
  React.useEffect(() => {
    if (prevOpen.current === true && openSettings === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openSettings;
  }, [openSettings]);

  return (
    <div className={classes.root}>

      <CssBaseline />
      <AppBar
        position="absolute"
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={open ? classes.menuButtonHidden : classes.menuButton}
            size="large">
            <MenuIcon />
          </IconButton> */}

          <a href="/"><img src={logo} width="200" className={classes.title} alt="Panasonic Connect Europe Logo" /></a>
          <Grid container justifyContent="flex-end">
            <div>
              {/* <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  Toggle Menu Grow
                </Button> */}
              <IconButton
                ref={anchorRef}
                aria-controls={openSettings ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
                className={classes.toolbarButton}
                size="large">
                {/* <Badge badgeContent={4} color="secondary"> */}
                <PersonIcon />
                {/* </Badge> */}
              </IconButton>
              <Popper open={openSettings} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseSettings}>
                        <MenuList autoFocusItem={openSettings} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          {/* <Paper className={classes.emailPaper}>
                            <Typography variant="body2">
                              {user.email}
                            </Typography>
                          </Paper> */}

                          

                          <MenuItem onClick={redirectToSettings}>
                            <Typography variant="body1" className={classes.settingstext}>
                              Account settings
                            </Typography>
                          </MenuItem>

                          <MenuItem onClick={handleLogout}>
                            <Typography variant="body1" className={classes.logouttext}>
                              Logout
                            </Typography>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Grid>



        </Toolbar>
      </AppBar>
      {/* {user && (
        <Drawer
          variant={props.width !== "xs" ? "permanent" : "temporary"}
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              <ListItem button onClick={function(event){ redirectToStart(); handleDrawerClose()}}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>

              {user.appUserRole !== "NEW" && (
                <ListItem button onClick={function(event){ redirectToBooking(); handleDrawerClose()}}>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="Booking" />
                </ListItem>
              )}

              {user.appUserRole !== "NEW" && (
                <ListItem button onClick={function(event){ redirectToWaitingRoom(); handleDrawerClose()}}>
                  <ListItemIcon>
                    <PlayCircleFilledIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tour" />
                </ListItem>
              )}


              {user.appUserRole === "ADMIN" && (
                <div>
                  <Divider />
                  <ListItem button onClick={function(event){ redirectToAdmin(); handleDrawerClose()}}>
                    <ListItemIcon>
                      <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                  <ListItem button onClick={function(event){ redirectToTour(); handleDrawerClose()}}>
                    <ListItemIcon>
                      <WallpaperIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tour preview" />
                  </ListItem>
                </div>
              )}
              <Divider />


              <ListItem button component="a" href="https://business.panasonic.de/impressum" target="_blank" rel="noopener noreferrer">
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Imprint" />
              </ListItem>


              <ListItem button component="a" href="https://business.panasonic.de/datenschutzrichtlinie" target="_blank" rel="noopener noreferrer">
                <ListItemIcon>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText primary="GDPR" />
              </ListItem>




            </div>
          </List>
        </Drawer>
      )} */}

      <main
        className={
          props.location.pathname === "/dashboard/tour"
            ? classes.darkcontent
            : classes.lightcontent
        }
      >
        <div className={classes.appBarSpacer} />
        <Container
          maxWidth="xl"
          className={classes.container}
          disableGutters={props.width === "xs"}
        >
          <Content user={user} />
        </Container>
        <BottomLinks />
      </main>
    </div>
  );
}

export default withRouter(Dashboard);
