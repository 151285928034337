import { useEffect, useState } from "react";
import { useServerApi } from "../../../shared/controller/useServerApi";

export const useRegister = (props) => {
    const { register } = useServerApi(props);
    const [agreedToAGB, setAgreedToAGB] = useState(false);
    const [agreedToGDPR, setAgreedToGDPR] = useState(false);
    const [registrationResponse, setRegistrationResponse] = useState();

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://www.google.com/recaptcha/api.js?render=6LeGhdQjAAAAAB_xc4eIUz9w3b3Om4gyNIHOZQze";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const [state, setState] = useState({
        email: "",
        firstname: "",
        lastname: "",
        mobile: "",
        password: "",
        passwordConfirm: "",
        company: "",
    })
    const [industry, setIndustry] = useState("");
    const [branch, setBranch] = useState("");
    const [country, setCountry] = useState("");

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleIndustryChange = (event) => {
        setIndustry(event.target.value);
    };

    const handleBranchChange = (event) => {
        setBranch(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleSubmit = (e) => {
        const grecaptcha = window.grecaptcha;
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute('6LeGhdQjAAAAAB_xc4eIUz9w3b3Om4gyNIHOZQze', { action: 'submit' }).then(function (token) {
                register(
                    state.email,
                    state.firstname,
                    state.lastname,
                    state.mobile,
                    state.password,
                    state.passwordConfirm,
                    state.company,
                    industry,
                    branch,
                    country,
                    setRegistrationResponse,
                    agreedToAGB,
                    agreedToGDPR,
                    token
                );
            });
        });

    }

    const handleCheckboxChange = (event) => {
        setAgreedToAGB(event.target.checked);
    };

    const handleGDPRCheckboxChange = (event) => {
        setAgreedToGDPR(event.target.checked);
    };


    const countryList = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Armenia", "Austria", "Azerbaijan", "Bahrain", "Belarus", "Belgium", "Benin", "Bosnia and Herzegovina", "Botswana", "Bulgaria", "Burkina Faso", "Cape Verde", "Cameroon", "Central African Republic (CAR)", "Chad", "Comoros", "Croatia", "Cyprus", "Czech Republic", "Democratic Republic of the Congo", "Denmark", "Djibouti", "Egypt", "Equatorial Guinea", "Estonia", "Ethiopia", "Faroe Islands (the)", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Hungary ", "Iceland", "Ireland", "Italy", "Jordan", "Kazakhstan", "Kenya", "Kosovo", "Kuwait", "Latvia", "Lesotho", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macedonia (the Republic of)", "Madagascar", "Malawi", "Mali", "Malta", "Mauritania", "Mauritius", "Moldova (the Republic of) ", "Monaco", "Montenegro", "Morocco", "Mozambique", "Namibia", "Netherlands", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Poland", "Portugal", "Romania", "Qatar", "Russia", "Rwanda", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Slovakia", "Slovenia", "Spain", "South Africa", "Swaziland", "Sweden", "Switzerland", "Tanzania", "Togo", "Tunesia", "Turkey", "Uganda", "Ukraine", "United Arab Emeriates", "United Kingdom of Great Britain and Northern Ireland (the)", "Zambia"
    ];

    return {
        state,
        handleChange,
        handleSubmit,
        handleIndustryChange,
        industry,
        handleBranchChange,
        branch,
        handleCountryChange,
        country,
        registrationResponse,
        handleCheckboxChange,
        agreedToAGB,
        handleGDPRCheckboxChange,
        agreedToGDPR,
        countryList
    };
}