import { createTheme } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import EmailConfirm from "../Signing/EmailConfirm/EmailConfirm";
import Login from "../Signing/Login/Login";
import PasswordReset from "../Signing/PasswordReset/PasswordReset";
import UpdatePassword from "../Signing/PasswordReset/UpdatePassword/UpdatePassword";
import Register from "../Signing/Register/Register";
import Verify from "../Signing/Verify/Verify";

function App() {
  // const classes = useStyles();
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = createTheme({
    palette: {
      mode: 'light',
      // mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: "#006ab0",
        // main: "#00ADEC",

      },
      secondary: {
        // main: orange[800],
        main: "#B9261C",
      },
      background: {
        // default: "#000000",
        // paper: "#1D1D1F",
      },
      action: {
        // active: "#fff",
        // control: "#464647",
      },
    },
  });


  return (
    <StyledEngineProvider injectFirst>
      {/* <Protect sha512='5e0ad9a3810f5d265ab9d04fe88efe4cfe9cea83cb7fe2a0a192528e6b7bbe363e87864f16c13295fa07e5453199745e46045c3e402e434353760331e7c576d1'>
</Protect> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <PrivateRoute exact path="/">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/confirm">
              <EmailConfirm />
            </Route>
            <Route path="/verify/:token">
              <Verify />
            </Route>
            <Route path="/reset">
              <PasswordReset />
            </Route>
            <Route path="/updatepassword/:token">
              <UpdatePassword />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
