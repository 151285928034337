import { makeStyles } from "@mui/styles";

const styleConfirmation = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  root: {
    // minWidth: 275,
    minWidth: "60%",
    // height: theme.spacing(20),
    padding: 20,
    margin: 20,
    // backgroundColor: "green"
  },
  groot: {
    height: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    // color: 'white'
  },
  datatext: {
    // color: 'white'
  },
  pos: {
    marginBottom: 12,
  },
  pickertext: {
    // padding: 10,
    marginBottom: 20,
  },
}));

export { styleConfirmation };
