export const useConfirmation = () => {
  const getSlotStartTimeString = (date) => {
    // // console.log(date);
    var starttime = new Date(date);
    let hours = starttime.getHours().toString().padStart(2, "0");
    let minutes = starttime.getMinutes().toString().padStart(2, "0");
    let string = hours + ":" + minutes;
    return string;
  };

  const getSlotEndTimeString = (date) => {
    var endtime = new Date(date);
    endtime.setMinutes(endtime.getMinutes() + 30);
    let hours = endtime.getHours().toString().padStart(2, "0");
    let minutes = endtime.getMinutes().toString().padStart(2, "0");
    let string = hours + ":" + minutes;
    return string;
  };

  return { getSlotStartTimeString, getSlotEndTimeString };
};
