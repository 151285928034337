import { useEffect, useState } from "react";
import { useServerApi } from "../../shared/controller/useServerApi";

export const useSettings = (props) => {
    const [id, setId] = useState(0);
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [industry, setIndustry] = useState("");
    const [branch, setBranch] = useState("");
    const [country, setCountry] = useState("");

    const { deleteUser } = useServerApi(props);

    // const [rows, setRows] = useState([]);


    useEffect(() => {


        if (props.user !== undefined) {
            // // console.log(props.user);
            setId(props.user.id);
            setEmail(props.user.email);
            setCompany(props.user.company);
            setIndustry(props.user.industry);
            setBranch(props.user.branch);
            setCountry(props.user.country);

            // console.log(props.user.email);
        }
    }, [props.user]);

    const [passwordDeleteModalOpen, setPasswordDeleteModalOpen] = useState(false);

    const handlePasswordDeleteModalClickOpen = () => {
        setPasswordDeleteModalOpen(true);
    };

    const handlePasswordDeleteModalClose = () => {
        setPasswordDeleteModalOpen(false);
    };



    return { 
        id,
        email, 
        company, 
        industry, 
        branch, 
        country, 
        passwordDeleteModalOpen,
        handlePasswordDeleteModalClickOpen, 
        handlePasswordDeleteModalClose,
        deleteUser
     };
}